import React, { useEffect, useContext, useCallback, useState } from "react";

import {
  Divider,
  Grid,
  Paper,
  Box,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import { AppContext, UserContext } from "../../Contexts";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "../../Axios";
import { User } from "common/src/models";
import Auth from "../../Auth";

const PREMIUM_PLAN: number = 1;
const PRO_PLAN: number = 2;
const BASIC_PLAN: number = 3;

interface ProfileFormValues {
  firstName?: string;
  lastName?: string;
  email?: string;
}
const ProfileFormValidation = Yup.object().shape({
  firstName: Yup.string().required("* Required"),
  lastName: Yup.string().required("* Required"),
  email: Yup.string().required("* Required"),
});

interface ChangePasswordFormValues {
  currentPassword?: string;
  password?: string;
  confirmPassword?: string;
}
const ChangePassFormValidation = Yup.object().shape({
  currentPassword: Yup.string().required("* Required"),
  password: Yup.string().required("* Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords don't match")
    .required("* Required"),
});

const CurrentPlan: React.FC = () => {
  const userCtx = useContext(UserContext);

  const handleAddPlanClick = async (planId: number) => {
    if (userCtx.user) {
      const userId = userCtx.user.id;
      const response = await axios.post("/stripeCheckout", { userId, planId });
      const url = response.data;
      console.log(url);
      window.location.href = url;
    }
  };

  const PLAN_DESCRIPTIONS = {
    [BASIC_PLAN]: "Post up to Twenty Five times on a Single Site",
    [PRO_PLAN]: "Post up to Seventy Five times across Three sites",
    [PREMIUM_PLAN]: "Post up to Two Hundred times across Five sites",
  };

  return (
    <Paper>
      <Box p={4}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h6">
              {userCtx.user?.plan
                ? userCtx.user.plan.name
                : "Currently on Free Trial"}
            </Typography>
            {userCtx.user && userCtx.user.expired && (
              <Typography variant="subtitle2" color="error">
                Expired
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography style={{ paddingLeft: "16px" }}>
                      {PLAN_DESCRIPTIONS[BASIC_PLAN]}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleAddPlanClick(BASIC_PLAN)}
                    >
                      Add Basic Plan
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography style={{ paddingLeft: "16px" }}>
                      {PLAN_DESCRIPTIONS[PRO_PLAN]}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddPlanClick(PRO_PLAN)}
                    >
                      Add Pro Plan
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography style={{ paddingLeft: "16px" }}>
                      {PLAN_DESCRIPTIONS[PREMIUM_PLAN]}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={() => handleAddPlanClick(PREMIUM_PLAN)}
                    >
                      Add Premium Plan
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const ProfileForm: React.FC<{ user?: User; updated: (u: User) => void }> = ({
  user,
  updated,
}) => {
  const appCtx = useContext(AppContext);
  const [initialValues, setInitialValues] = useState<ProfileFormValues>({
    firstName: "",
    lastName: "",
    email: "",
  });

  useEffect(() => {
    setInitialValues({
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
    });
  }, [user]);

  const handleSubmit = async (values: ProfileFormValues) => {
    try {
      const token = Auth.token();
      const userData = await axios.patch<{
        result: User;
        token: string;
        expiresIn: number;
      }>(`/user`, values);

      if (token?.appKey) {
        Auth.login(userData.data.token, userData.data.expiresIn, token.appKey);
        updated(userData.data.result);
      }
    } catch (err: any) {
      if (err.response?.data?.error) {
        console.log(`Failed with error: `, err.response.data.error);
        appCtx.setError(err.response.data.error);
      } else {
        console.log(`Failed with unknown error: `, err);
        appCtx.setError("Oops, something went wrong");
      }
    }
  };

  return (
    <Paper>
      <Box p={4}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => handleSubmit(values)}
          validationSchema={ProfileFormValidation}
          enableReinitialize={true}
          validateOnBlur
          validateOnChange
          validateOnMount
        >
          {(formik) => {
            return (
              <form
                onSubmit={(e) => {
                  formik.handleSubmit();
                  e.preventDefault();
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      error={
                        formik.errors.firstName != null &&
                        formik.touched.firstName
                      }
                      helperText={
                        formik.errors.firstName &&
                        formik.touched.firstName &&
                        formik.errors.firstName
                      }
                      name="firstName"
                      label="First Name"
                      value={formik.values.firstName ?? ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      error={
                        formik.errors.lastName != null &&
                        formik.touched.lastName
                      }
                      helperText={
                        formik.errors.lastName &&
                        formik.touched.lastName &&
                        formik.errors.lastName
                      }
                      name="lastName"
                      label="Last Name"
                      value={formik.values.lastName ?? ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      error={
                        formik.errors.email != null && formik.touched.email
                      }
                      helperText={
                        formik.errors.email &&
                        formik.touched.email &&
                        formik.errors.email
                      }
                      name="email"
                      label="Email"
                      value={formik.values.email ?? ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      disabled={!formik.dirty}
                      size="large"
                      fullWidth
                      color="secondary"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </Box>
    </Paper>
  );
};

const ChangePasswordForm: React.FC<{ user?: User }> = () => {
  const appCtx = useContext(AppContext);
  const [initialValues, setInitialValues] = useState<ChangePasswordFormValues>({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const handleSubmit = async (values: ChangePasswordFormValues) => {
    try {
      const result = await axios.post<{ result: boolean }>(
        `/user/changepassword`,
        values
      );
      if (result.data.result) {
        setInitialValues({});
        appCtx.setSuccessMessage("Reset Password");
      } else {
        appCtx.setError("Failed to update password");
      }
    } catch (err: any) {
      if (err.response?.data?.error) {
        console.log(`Failed with error: `, err.response.data.error);
        appCtx.setError(err.response.data.error);
      } else {
        console.log(`Failed with unknown error: `, err);
        appCtx.setError("Oops, something went wrong");
      }
    }
  };

  return (
    <Paper>
      <Box p={4}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => handleSubmit(values)}
          validationSchema={ChangePassFormValidation}
          enableReinitialize={true}
          validateOnBlur
          validateOnChange
          validateOnMount
        >
          {(formik) => {
            return (
              <form
                onSubmit={(e) => {
                  formik.handleSubmit();
                  e.preventDefault();
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="password"
                      variant="outlined"
                      error={
                        formik.errors.currentPassword != null &&
                        formik.touched.currentPassword
                      }
                      helperText={
                        formik.errors.currentPassword &&
                        formik.touched.currentPassword &&
                        formik.errors.currentPassword
                      }
                      name="currentPassword"
                      label="Current Password"
                      value={formik.values.currentPassword ?? ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="password"
                      variant="outlined"
                      error={
                        formik.errors.password != null &&
                        formik.touched.password
                      }
                      helperText={
                        formik.errors.password &&
                        formik.touched.password &&
                        formik.errors.password
                      }
                      name="password"
                      label="Password"
                      value={formik.values.password ?? ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="password"
                      variant="outlined"
                      error={
                        formik.errors.confirmPassword != null &&
                        formik.touched.confirmPassword
                      }
                      helperText={
                        formik.errors.confirmPassword &&
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                      name="confirmPassword"
                      label="Confirm Password"
                      value={formik.values.confirmPassword ?? ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      disabled={!formik.dirty}
                      size="large"
                      fullWidth
                      color="secondary"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </Box>
    </Paper>
  );
};

export const Profile: React.FC = () => {
  const appCtx = useContext(AppContext);
  const [user, setUser] = useState<User>();

  const fetch = useCallback(() => {
    const getData = async () => {
      try {
        const userData = await axios.get<{ result: User }>(`/user`);
        setUser(userData.data.result);
      } catch (err: any) {
        if (err.response?.data?.error) {
          console.log(`Failed with error: `, err.response.data.error);
          appCtx.setError(err.response.data.error);
        } else {
          console.log(`Failed with unknown error: `, err);
          appCtx.setError("Oops, something went wrong");
        }
      }
    };

    getData();
  }, []);

  useEffect(() => {
    appCtx.setHeader({
      title: "Profile",
    });

    fetch();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">Plan</Typography>
      </Grid>
      <Grid item xs={12}>
        <CurrentPlan />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Profile</Typography>
      </Grid>
      <Grid item xs={12}>
        <ProfileForm user={user} updated={(u) => setUser(u)} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Change Password</Typography>
      </Grid>
      <Grid item xs={12}>
        <ChangePasswordForm user={user} />
      </Grid>
    </Grid>
  );
};
export default Profile;
