import React from 'react';
import {
    Button,
    Link as MuiLink,
    // PropTypes,
    ButtonProps
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

export const ButtonLink: React.FC<ButtonProps & { to: string }> = ({ to, children, ...rest }) => {
    return (
        <RouterLink to={to} style={{ color: 'inherit', textDecoration: 'inherit'}}>
            <Button { ...rest }>
                {children}
            </Button>
        </RouterLink>
    );
};

export const Link: React.FC<{ to: string }> = ({ to, children }) => {
    return (
        <MuiLink color="inherit" underline='none' component={RouterLink} to={to}>
            {children}
        </MuiLink>
    );
};
