import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Typography,
  Grid,
  CardActions,
  IconButton,
  makeStyles,
  LinearProgress,
  Chip,
  Tooltip,
} from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { Post } from "common/src/models";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { format } from "date-fns";
import emptyImage from "../../Images/empty-state.png";
import { zoomStyle } from "../../Styles/zoom";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  card: {
    height: 470,
    cursor: "pointer",
    position: "relative",
  },
  cardContent: {
    height: 240,
  },
  cardCategories: {
    marginTop: 10,
  },
  cardImage: {
    height: 150,
  },
  statusBar: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: 22,
    paddingLeft: 15,
    paddingRight: 15,
  },
  statusPublished: {
    backgroundColor: theme.palette.secondary.light,
  },
  statusNotPublished: {
    backgroundColor: "#f5f5f5",
  },
  postStatus: {
    paddingTop: 1,
    paddingBottom: 1,
  },
}));

export const PostCard: React.FC<{ post: Post }> = ({ post }) => {
  const styles = useStyles();
  const [raised, setRaised] = useState(false);
  const zoom = zoomStyle(1.005)();

  const toggleRaised = () => setRaised(!raised);

  return (
    <Link
      underline="none"
      component={RouterLink}
      to={`/dashboard/posts/${post.id}`}
    >
      <Card
        className={clsx(styles.card, zoom.root)}
        raised={raised}
        onMouseOver={toggleRaised}
        onMouseOut={toggleRaised}
      >
        <CardActionArea>
          <CardMedia
            className={styles.cardImage}
            image={post.thumbnailSm ? post.thumbnailSm.url : emptyImage}
            title={post.title}
          />
          <CardContent className={styles.cardContent}>
            <Typography gutterBottom variant="h5" component="h2">
              {post.title}
            </Typography>
            {post.finished && post.subtitle && (
              <Typography variant="body2" color="textSecondary" component="p">
                {post.subtitle}
              </Typography>
            )}
            {post.categories && (
              <div className={styles.cardCategories}>
                {post.categories.slice(0, 4).map((category) => (
                  <Chip
                    key={`card_chip_${category.id}`}
                    label={category.name}
                    style={{ marginRight: 7 }}
                  />
                ))}
              </div>
            )}
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Grid container justify="flex-end">
            <Grid item>
              <Tooltip title="View Post">
                <IconButton>
                  <ArrowForward />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </CardActions>
        <div
          className={`${styles.statusBar} ${
            post.finished
              ? post.publishedDate
                ? styles.statusPublished
                : styles.statusNotPublished
              : ""
          }`}
        >
          {!post.finished && <LinearProgress color="secondary" />}
          {post.finished && (
            <Typography
              variant="body2"
              component="p"
              className={styles.postStatus}
            >
              {post.publishedDate
                ? `Scheduled for ${format(
                    new Date(post.publishedDate),
                    "MM/dd/yy"
                  )}`
                : "Not Scheduled"}
            </Typography>
          )}
        </div>
      </Card>
    </Link>
  );
};
export default PostCard;
