import React, {
  useEffect,
  useContext,
  useState,
  useImperativeHandle,
  useRef,
} from "react";
import {
  Grid,
  TextField,
  Button,
  Avatar,
  Divider,
  Typography,
  Box,
} from "@material-ui/core";
import { PhotoRounded } from "@material-ui/icons";
import axios from "../../Axios";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { AppContext, UserContext } from "../../Contexts";
import { Attachment, Site, UploadFileResponse } from "common/src/models";

export interface SiteCreationForm {
  name?: string;
  url?: string;
  logo?: Attachment;
}

const Validation = Yup.object().shape({
  name: Yup.string().required("* Required"),
  url: Yup.string()
    .required("* Required")
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url"
    ),
});

interface SiteDetailProps {
  refresh?: (s: Site) => void;
  site?: Site;
  updatedLoading?: (l: boolean) => void;
  updatedIsValid?: (v: boolean) => void;
}

export type SiteDetailHandle = { triggerSubmit: () => void };

const SiteDetailInner: React.ForwardRefRenderFunction<
  SiteDetailHandle,
  SiteDetailProps
> = ({ site, refresh, updatedLoading, updatedIsValid }, ref) => {
  const appCtx = useContext(AppContext);
  const userCtx = useContext(UserContext);
  const [initialValues, setInitialValues] = useState({
    name: "",
    url: "",
  } as SiteCreationForm);
  const formRef = useRef<FormikProps<SiteCreationForm> | null>(null);
  const [file, setFile] = useState<File>();

  useEffect(() => {
    if (site) {
      setInitialValues({ name: site.name, url: site.url, logo: site.logo });
    } else {
      setInitialValues({ name: "", url: "" });
    }
  }, [site]);

  const handleSubmit = async (values: SiteCreationForm) => {
    if (updatedLoading) updatedLoading(true);
    try {
      let logoUrl = values.logo;
      if (file) {
        logoUrl = await uploadFile();
      }

      if (site) {
        const result = await axios.patch<{ result?: Site }>(
          `/sites/${site.id}`,
          {
            ...values,
            logoId: logoUrl?.id ?? site.logo.id,
          }
        );
        if (result.data.result && refresh) {
          refresh(result.data.result);
          userCtx.setUser(undefined);
          setFile(undefined);
        }
      } else {
        const result = await axios.post<{ result?: Site }>(`/sites`, {
          ...values,
          logoId: logoUrl?.id,
        });
        if (result.data.result && refresh) {
          refresh(result.data.result);
          userCtx.setUser(undefined);
          setFile(undefined);
        }
      }

      if (updatedLoading) updatedLoading(false);
    } catch (err: any) {
      if (err.response?.data?.error) {
        console.log(`Failed with error: `, err.response.data.error);
        appCtx.setError(err.response.data.error);
      } else {
        console.log(`Failed with unknown error: `, err);
        appCtx.setError("Oops, something went wrong");
      }

      if (updatedLoading) updatedLoading(false);
    }
  };

  const changedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const uploadFile = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("createThumbnail", "false");

      try {
        const result = await axios.post<UploadFileResponse>(
          `/photos/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (result.data.image) {
          return result.data.image;
        }
      } catch (err: any) {
        if (err.response?.data?.error) {
          console.log(`Failed with error: `, err.response.data.error);
          appCtx.setError(err.response.data.error);
        } else {
          console.log(`Failed with unknown error: `, err);
          appCtx.setError("Oops, something went wrong");
        }

        return undefined;
      }
    }

    return undefined;
  };

  useImperativeHandle(ref, () => ({
    triggerSubmit: () => {
      formRef.current?.submitForm();
    },
  }));

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values)}
      validationSchema={Validation}
      enableReinitialize={true}
      validateOnBlur
      validateOnChange
      validateOnMount
    >
      {(formik) => {
        formRef.current = formik;
        if (updatedIsValid) updatedIsValid(formik.isValid);

        console.log(`file: `, file);

        return (
          <form
            onSubmit={(e) => {
              formik.handleSubmit();
              e.preventDefault();
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={2}>
                <Button component="label">
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                  >
                    <Grid item>
                      {file ? (
                        <Avatar
                          style={{ width: 80, height: 80 }}
                          src={URL.createObjectURL(file)}
                        />
                      ) : (
                        <>
                          {formik.values.logo &&
                          formik.values.logo.url.length > 0 ? (
                            <Avatar
                              style={{ width: 80, height: 80 }}
                              src={formik.values.logo.url}
                            />
                          ) : (
                            <Avatar style={{ width: 80, height: 80 }}>
                              <PhotoRounded fontSize="large" />
                            </Avatar>
                          )}
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      Logo
                    </Grid>
                  </Grid>
                  <input
                    hidden
                    type="file"
                    accept="image/*"
                    multiple={false}
                    onChange={changedFile}
                  />
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={10}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      error={formik.errors.name != null && formik.touched.name}
                      helperText={
                        formik.errors.name &&
                        formik.touched.name &&
                        formik.errors.name
                      }
                      name="name"
                      label="Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      error={formik.errors.url != null && formik.touched.url}
                      helperText={
                        formik.errors.url &&
                        formik.touched.url &&
                        formik.errors.url
                      }
                      name="url"
                      label="URL"
                      value={formik.values.url}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {site && (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      <Box fontWeight="bold">Site Token</Box> {site.authToken}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>

            <Button type="submit" style={{ opacity: 0 }}>
              Submit
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
export const SiteDetail = React.forwardRef(SiteDetailInner);
export default SiteDetail;
