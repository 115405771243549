import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation, Redirect, useHistory, RouteComponentProps, RouteProps, useRouteMatch } from 'react-router-dom';
import { Location } from 'history';
import { Drawer, NavRoute, PublicRouteHeader, PublicRouteFooter } from './Components/Common';
import {
  Posts,
  Schedule,
  Categories,
  Home,
  Login,
  SignUp,
  Pricing,
  Profile,
  ResetPasswordConfirmation,
  ResetPassword,
  ContactUs,
  PrivacyPolicy,
  Sites
} from './Components/Pages';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { HomeRounded, TodayRounded, CategoryRounded, LanguageRounded } from '@material-ui/icons';
import { AppContext, AppProvider, UserProvider } from './Contexts';
import Auth from './Auth';
import './Images/fonts.css';
import config from './config';

console.log(`config: `, config);

const routes: NavRoute[] = [
  {
    name: 'Posts',
    icon: <HomeRounded />,
    path: '/dashboard/posts',
    component: Posts
  },
  {
    name: 'Schedule',
    icon: <TodayRounded />,
    path: '/dashboard/schedule',
    component: Schedule
  },
  {
    name: 'Categories',
    icon: <CategoryRounded />,
    path: '/dashboard/categories',
    component: Categories
  },
  {
    name: 'Sites',
    icon: <LanguageRounded />,
    path: '/dashboard/sites',
    component: Sites
  }
]

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#6200ee',
      light: '#9e47ff',
      dark: '#0400ba',
    },
    secondary: {
      main: '#03dac6',
      light: '#66fff9',
      dark: '#00a896'
    },
  },
  typography: {
    fontFamily: 'AvenirNext-Regular',
    h1: {
      fontFamily: 'AvenirNext-Bold'
    },
    h2: {
      fontFamily: 'AvenirNext-Bold'
    },
    h3: {
      fontFamily: 'AvenirNext-Bold'
    },
    h4: {
      fontFamily: 'AvenirNext-Bold'
    },
    h5: {
      fontFamily: 'AvenirNext-Bold'
    },
    h6: {
      fontFamily: 'AvenirNext-Medium'
    },
    body1: {
      fontFamily: 'AvenirNext-Regular'
    },
    body2: {
      fontFamily: 'AvenirNext-Regular'
    },
    button: {
      fontFamily: 'AvenirNext-Medium'
    },
    overline: {
      fontFamily: 'AvenirNext-Medium'
    },
  },
  overrides: {
    MuiLink: {
      root: {
        fontFamily: 'AvenirNext-Medium'
      }
    }
  }
});

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      margin: 10
    },
    [theme.breakpoints.up("md")]: {
      margin: 25
    },
    [theme.breakpoints.up("lg")]: {
      margin: 40
    },
  },
  mainContainer: {
    position: 'relative',
    paddingBottom: 100
  }
}));

const PrivateRoute: React.FC<{ component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> } & RouteProps> = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        Auth.isLoggedIn() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const PrivateRouteContainer: React.FC = () => {
  const appCtx = useContext(AppContext);
  const styles = useStyles();
  const history = useHistory();
  const loc = useLocation();
  const match = useRouteMatch('/dashboard');

  Auth.setHeaders();

  const updateMargin = (location?: Location) => {
    const l = location ? location : loc;
    if (l) {
      if (new RegExp("/dashboard/posts/.*").test(l.pathname)) {
        if (appCtx.mainMarginEnabled) appCtx.setMainMarginEnabled(false);
      } else {
        if (!appCtx.mainMarginEnabled) appCtx.setMainMarginEnabled(true);
      }
    }
  };

  history.listen((location, action) => {
    updateMargin(location);
  });
  useEffect(() => {
    updateMargin();
  }, [appCtx.loggedIn]);

  if (match?.isExact) return <Redirect to="/dashboard/posts" />;

  return (
    <UserProvider>
      <Drawer routes={routes}>
        <div className={appCtx.mainMarginEnabled ? styles.container : ''}>
          {routes.map(r => (
            <PrivateRoute key={`router_${r.path}`} path={r.path} component={r.component} />
          ))}
          <PrivateRoute path={'/dashboard/profile'} component={Profile} />
        </div>
      </Drawer>
    </UserProvider>
  );
};

const PublicRouterContainer: React.FC = () => {
  const match = useRouteMatch('/dashboard');
  const styles = useStyles();

  return (
    <div className={!match ? styles.mainContainer : ''}>
      {!match && <PublicRouteHeader />}

      <Route
        exact
        path="/"
        component={Home}
      />
      <Route
        exact
        path="/login"
        component={Login}
      />
      <Route
        exact
        path="/signup"
        component={SignUp}
      />
      <Route
        exact
        path="/pricing"
        component={Pricing}
      />
      <Route
        exact
        path="/resetpassword"
        component={ResetPassword}
      />
      <Route
        exact
        path="/resetpasswordconfirmation/:token?"
        component={ResetPasswordConfirmation}
      />
      <Route
        exact
        path="/contact"
        component={ContactUs}
      />
      <Route
        exact
        path="/privacy"
        component={PrivacyPolicy}
      />

      <Route
        path="/dashboard"
        component={PrivateRouteContainer}
      />

      {!match && <PublicRouteFooter />}
    </div>
  );
}

const App = () => {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <AppProvider>
            <Switch>
              <PublicRouterContainer />
            </Switch>
          </AppProvider>
        </Router>
      </ThemeProvider>
    </div>
  );
};

export default App;
