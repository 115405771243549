import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import './index.css';
import ReactGA from 'react-ga4';

// Below code enables Google Analytics
// Swap ReactGA.initialize to enable debug mode
// Download Google Analytics Debugger extension to see debug in console
// https://chrome.google.com/webstore/detail/google-analytics-debugger/jnkmfdileelhofjcijamephohjechhna?hl=en
ReactGA.initialize('G-C8YFMK3P0D');
// ReactGA.initialize([
//   {
//     trackingId: 'G-C8YFMK3P0D',
//     gaOptions: {
//       debug_mode: true,
//     },
//     gtagOptions: {
//       debug_mode: true,
//     },
//   },
// ]);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
