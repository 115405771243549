import React from 'react';
import {
    Typography,
    Avatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Site } from 'common/src/models';

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: 25,
        height: 25
    }
}));

export const SiteLogo: React.FC<{ site: Site }> = ({ site }) => {
    const styles = useStyles();

    if (site.logo && site.logo.url.length > 0) {
        return (
            <Avatar className={styles.avatar} src={site.logo.url} />
        );
    }

    const matches = site.name.match(/\b(\w)/g) ?? [];
    const acronym = (matches.length > 0 ? matches.join('') : site.name.charAt(0)).toUpperCase();

    return (
        <Avatar className={styles.avatar}>
            <Typography variant="body1" style={{ fontSize: 12 }}>{acronym}</Typography>
        </Avatar>
    );
};
export default SiteLogo;
