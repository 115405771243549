import React, { useContext, useEffect } from 'react';
import {
    Grid,
    TextField,
    makeStyles,
    Button,
    Paper,
    Typography,
    Checkbox
} from '@material-ui/core';
// import { ButtonLink } from '../Common';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from '../../Axios';
import { AppContext } from '../../Contexts';
import { LoginResponse } from 'common/src/models';
import Auth from '../../Auth';
import { useHistory } from 'react-router-dom';

interface SignupForm {
    email?: string;
    firstName?: string;
    lastName?: string;
    password?: string;
    confirmPassword?: string;
}

const initialValues: SignupForm = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: ''
};

const Validation = Yup.object().shape({
    email: Yup.string().required('* Required'),
    password: Yup.string().required('* Required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], "Passwords don't match").required('* Required'),
});

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: `${theme.palette.primary.main}`,
        // width: '100vw',
        height: '100vh'
    },
    signupForm: {
        padding: 20,
        [theme.breakpoints.up("md")]: {
            padding: 40
        }
    }
}));

const SignupForm: React.FC = () => {
    const appCtx = useContext(AppContext);
    const history = useHistory();

    useEffect(() => {
        if (appCtx.loggedIn) {
            history.push('/dashboard');
        }
    }, [appCtx.loggedIn]);

    const handleSubmit = async (values: SignupForm) => {
        try {
            const result = await axios.post<LoginResponse>(`/signup`, {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                password: values.password
            });
            if (result.data.result.token) {
                Auth.login(result.data.result.token, result.data.result.expiresIn, undefined);
                appCtx.setLoggedIn(true);
            }
        } catch (err: any) {
            if (err.response?.data?.error) {
                console.log(`Failed with error: `, err.response.data.error);
                appCtx.setError(err.response.data.error);
            } else {
                console.log(`Failed with unknown error: `, err);
                appCtx.setError('Failed to signup');
            }
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={Validation}
            enableReinitialize={true}
            validateOnBlur
            validateOnChange
            validateOnMount
        >
            {formik => {
                return (
                    <form
                        onSubmit={(e) => {
                            formik.handleSubmit();
                            e.preventDefault();
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4">Signup</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    error={(formik.errors.email != null && formik.touched.email)}
                                    helperText={(formik.errors.email && formik.touched.email) && formik.errors.email}
                                    name="email"
                                    label="Email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    error={(formik.errors.firstName != null && formik.touched.firstName)}
                                    helperText={(formik.errors.firstName && formik.touched.firstName) && formik.errors.firstName}
                                    name="firstName"
                                    label="First Name"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    error={(formik.errors.lastName != null && formik.touched.lastName)}
                                    helperText={(formik.errors.lastName && formik.touched.lastName) && formik.errors.lastName}
                                    name="lastName"
                                    label="Last Name"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="password"
                                    error={(formik.errors.password != null && formik.touched.password)}
                                    helperText={(formik.errors.password && formik.touched.password) && formik.errors.password}
                                    name="password"
                                    label="Password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="password"
                                    error={(formik.errors.confirmPassword != null && formik.touched.confirmPassword)}
                                    helperText={(formik.errors.confirmPassword && formik.touched.confirmPassword) && formik.errors.confirmPassword}
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                </Grid>
                                <Grid item xs={12} style={{display: 'flex'}}>
                                          <Checkbox /> <Typography style={{alignContent: 'center', display: 'flex', flexWrap: 'wrap'}}>Agree to receive emails from Composie</Typography>
                                      </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" size="large" fullWidth color="secondary" variant="contained">Signup</Button>
                            </Grid>
                        </Grid>
                    </form>
                );
            }}
        </Formik>
    );
};

export const SignUp: React.FC = () => {
    const styles = useStyles();

    return (
        <Grid container alignItems="center" justify="center" className={styles.background}>
            <Grid item xs={11} sm={7} md={6} lg={4}>
                <Paper className={styles.signupForm}>
                    <SignupForm />
                </Paper>
            </Grid>
        </Grid>
    );
};
export default SignUp;
