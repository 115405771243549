import React, { useState } from 'react';
import {
    Snackbar,
    Slide,
    IconButton
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CancelRounded } from '@material-ui/icons';
import Auth from '../Auth';

export interface HeaderInfo {
    title?: string;
    actionTitle?: string;
    rightAction?: React.ReactNode;
}

export interface AppContextProps {
    mainMarginEnabled: boolean;
    setMainMarginEnabled: (enabled: boolean) => void;
    header?: HeaderInfo;
    setHeader: (header: HeaderInfo) => void;
    successMessage?: string;
    setSuccessMessage: (message: string) => void;
    error?: string;
    setError: (message: string) => void;
    loggedIn: boolean;
    setLoggedIn: (l: boolean) => void;
}

export const AppContext = React.createContext<AppContextProps>({
    mainMarginEnabled: true,
    setMainMarginEnabled: () => {},
    setHeader: () => {},
    setError: (e) => {},
    setSuccessMessage: (e) => {},
    loggedIn: false,
    setLoggedIn: (e) => {}
});

function TransitionUp(props: any) {
    return <Slide {...props} direction="up" />;
}

export const AppProvider: React.FC = (props) => {
    const [loggedIn, setLoggedIn] = useState(Auth.isLoggedIn());
    const [stateHeader, setStateHeader] = useState<HeaderInfo>();
    const [stateMainMargineEnabled, setStateMainMarginEnabled] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const closeSnackbar = () => {
        setError(undefined);
        setSuccessMessage(undefined);
    };

    return (
        <AppContext.Provider
            value={{
                mainMarginEnabled: stateMainMargineEnabled,
                setMainMarginEnabled: setStateMainMarginEnabled,
                header: stateHeader,
                setHeader: setStateHeader,
                error: error,
                setError: setError,
                successMessage,
                setSuccessMessage,
                loggedIn,
                setLoggedIn
            }}
        >
            {props.children}

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={error != null || successMessage != null}
                TransitionComponent={TransitionUp}
                onClose={closeSnackbar}
                key={`snackbar_error`}
                autoHideDuration={7000}
            >
                <Alert
                    severity={error != null ? 'error' : 'success'}
                    action={
                        <IconButton onClick={closeSnackbar}><CancelRounded /></IconButton>
                    }
                >
                    {error != null ? error : successMessage}
                </Alert>
            </Snackbar>
        </AppContext.Provider>
    );
};

export default AppProvider;
