import React, { useContext, useEffect } from 'react';
import {
    Grid,
    TextField,
    makeStyles,
    Button,
    Paper,
    Typography
} from '@material-ui/core';
import { ButtonLink } from '../Common';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from '../../Axios';
import { AppContext } from '../../Contexts';
import { LoginResponse } from 'common/src/models';
import Auth from '../../Auth';
import { useHistory } from 'react-router-dom';

interface LoginForm {
    email?: string;
    password?: string;
}

const initialValues: LoginForm = {
    email: '',
    password: ''
};

const Validation = Yup.object().shape({
    email: Yup.string().required('* Required'),
    password: Yup.string().required('* Required'),
});

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: `${theme.palette.primary.main}`,
        height: '100vh'
    },
    loginForm: {
        padding: 20,
        [theme.breakpoints.up("md")]: {
            padding: 40
        }
    }
}));

const LoginForm: React.FC = () => {
    const appCtx = useContext(AppContext);
    const history = useHistory();

    useEffect(() => {
        if (appCtx.loggedIn) {
            history.push('/dashboard');
        }
    }, [appCtx.loggedIn]);

    const handleSubmit = async (values: LoginForm) => {
        try {
            const result = await axios.post<LoginResponse>(`/login`, { email: values.email, password: values.password });
            if (result.data.result.token) {
                const site = result.data.result.user.sites && result.data.result.user.sites.length > 0 ? result.data.result.user.sites[0] : undefined;
                Auth.login(result.data.result.token, result.data.result.expiresIn, site?.authToken);
                appCtx.setLoggedIn(true);
            }
        } catch (err: any) {
            if (err.response?.data?.error) {
                console.log(`Failed with error: `, err.response.data.error);
                appCtx.setError(err.response.data.error);
            } else {
                console.log(`Failed with unknown error: `, err);
                appCtx.setError('Failed to login');
            }
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={Validation}
            enableReinitialize={true}
            validateOnBlur
            validateOnChange
            validateOnMount
        >
            {formik => {
                return (
                    <form
                        onSubmit={(e) => {
                            formik.handleSubmit();
                            e.preventDefault();
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4">Login</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    error={(formik.errors.email != null && formik.touched.email)}
                                    helperText={(formik.errors.email && formik.touched.email) && formik.errors.email}
                                    name="email"
                                    label="Email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="password"
                                    error={(formik.errors.password != null && formik.touched.password)}
                                    helperText={(formik.errors.password && formik.touched.password) && formik.errors.password}
                                    name="password"
                                    label="Password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" size="large" fullWidth color="secondary" variant="contained">Login</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <ButtonLink size="large" fullWidth to="/signup" color="primary">Signup</ButtonLink>
                            </Grid>
                            <Grid item xs={6}>
                                <ButtonLink size="large" fullWidth to="/resetpassword" color="primary">Forgot Password</ButtonLink>
                            </Grid>
                        </Grid>
                    </form>
                );
            }}
        </Formik>
    );
};

export const Login: React.FC = () => {
    const styles = useStyles();

    return (
        <Grid container alignItems="center" justify="center" className={styles.background}>
            <Grid item xs={11} sm={7} md={6} lg={4}>
                <Paper className={styles.loginForm}>
                    <LoginForm />
                </Paper>
            </Grid>
        </Grid>
    );
};
export default Login;
