import React, { useContext } from 'react';
import {
    Grid,
    TextField,
    makeStyles,
    Button,
    Paper,
    Typography
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from '../../Axios';
import { AppContext } from '../../Contexts';

interface LoginForm {
    email?: string;
    message?: string;
}

const initialValues: LoginForm = {
    email: '',
    message: ''
};

const Validation = Yup.object().shape({
    email: Yup.string().required('* Required'),
    message: Yup.string().required('* Required'),
});

const useStyles = makeStyles((theme) => ({
    background: {
      backgroundColor: `${theme.palette.primary.main}`,
        // width: '100vw',
        height: '100vh'
    },
    loginForm: {
        padding: 20,
        [theme.breakpoints.up("md")]: {
            padding: 40
        }
    }
}));

const ContactForm: React.FC = () => {
    const appCtx = useContext(AppContext);

    const handleSubmit = async (values: LoginForm) => {
        try {
            const result = await axios.post<{ result: boolean }>(`/contact`, values);
            if (result.data.result) {
                appCtx.setSuccessMessage('Sent Message');
            } else {
                appCtx.setError('Failed to send message');
            }
        } catch (err: any) {
            if (err.response?.data?.error) {
                console.log(`Failed with error: `, err.response.data.error);
                appCtx.setError(err.response.data.error);
            } else {
                console.log(`Failed with unknown error: `, err);
                appCtx.setError('Failed to send message');
            }
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={Validation}
            enableReinitialize={true}
            validateOnBlur
            validateOnChange
            validateOnMount
        >
            {formik => {
                return (
                    <form
                        onSubmit={(e) => {
                            formik.handleSubmit();
                            e.preventDefault();
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4">Contact</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    error={(formik.errors.email != null && formik.touched.email)}
                                    helperText={(formik.errors.email && formik.touched.email) && formik.errors.email}
                                    name="email"
                                    label="Email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="password"
                                    error={(formik.errors.message != null && formik.touched.message)}
                                    helperText={(formik.errors.message && formik.touched.message) && formik.errors.message}
                                    name="message"
                                    label="Message"
                                    value={formik.values.message}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    multiline
                                    rows={6}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" size="large" fullWidth color="secondary" variant="contained">Submit</Button>
                            </Grid>
                        </Grid>
                    </form>
                );
            }}
        </Formik>
    );
};

export const ContactUs: React.FC = () => {
    const styles = useStyles();

    return (
        <Grid container alignItems="center" justify="center" className={styles.background}>
            <Grid item xs={11} sm={7} md={6} lg={4}>
                <Paper className={styles.loginForm}>
                    <ContactForm />
                </Paper>
            </Grid>
        </Grid>
    );
};
export default ContactUs;
