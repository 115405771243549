import React, { useEffect, useContext, useState, useCallback } from 'react';
import { AppContext, UserContext } from '../../Contexts';
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper,
    CircularProgress,
    Grid,
    Toolbar,
    Typography,
    makeStyles,
    Box,
    IconButton,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions,
    Button,
    Tooltip
} from '@material-ui/core';
import { AddCircleRounded } from '@material-ui/icons';
import { Site } from 'common/src/models';
import axios from '../../Axios';
import { SiteLogo, ButtonLink, SiteDetailDialog } from '../Common';

const useStyles = makeStyles({
    title: {
        paddingLeft: 16,
        paddingRight: 16
    }
});

export const Sites: React.FC = () => {
    const appCtx = useContext(AppContext);
    const userCtx = useContext(UserContext);
    const styles = useStyles();
    const [sites, setSites] = useState<Site[]>([]);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialog] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [selectedSite, setSelectedSite] = useState<Site | undefined>(undefined);
    const toggleDialog = () => setDialog(!dialogOpen);
    const toggleErrorDialog = () => setErrorDialogOpen(!errorDialogOpen);

    const plusButtonClicked = () => {
        let allowedSites = 1;
        if (userCtx.user?.plan) {
            allowedSites = userCtx.user.plan.sitesAllowed;
        }

        if (userCtx.user?.sites && userCtx.user.sites.length >= allowedSites) {
            toggleErrorDialog();
        } else {
            setSelectedSite(undefined);
            toggleDialog();
        }
    }

  const fetch = useCallback(() => {
      console.log('I know how to use this thing!')
    const getData = async () => {
          console.log('dont make me do it')
            try {
                setLoading(true);
              const results = await axios.get<{ result: Site[] }>(`/sites`);
              console.log('tha result', results)
                setSites(results.data.result);
                setLoading(false);
            } catch (err: any) {
                if (err.response?.data?.error) {
                    console.log(`Failed with error: `, err.response.data.error);
                    appCtx.setError(err.response.data.error);
                } else {
                    console.log(`Failed with unknown error: `, err);
                    appCtx.setError('Oops, something went wrong');
                }

                setLoading(false);
            }
        }

        getData();
    }, [userCtx.currentSite]);

    useEffect(() => {
        appCtx.setHeader({
            title: 'Sites'
        });

        fetch();
    }, []);

    return (
        <>
            <Paper>
                {loading && (
                    <Grid container alignItems="center" justify="center" spacing={5}>
                        <Grid item>
                            <CircularProgress color="primary" />
                        </Grid>
                    </Grid>
                )}
                {!loading && (
                    <>
                        <Toolbar className={styles.title}>
                            <Grid container justify="space-between">
                                <Grid item>
                                    <Typography variant="h6" id="tableTitle" component="div">
                                        Sites
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Create Site">
                                        <IconButton color="secondary" onClick={plusButtonClicked}>
                                            <AddCircleRounded />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Toolbar>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell width={30} />
                                    <TableCell>
                                        <Box fontWeight="bold">Name</Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box fontWeight="bold">URL</Box>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sites.map(site => (
                                    <TableRow
                                        key={`site_${site.id}`}
                                        onClick={() => {
                                            setSelectedSite(site);
                                            toggleDialog();
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <TableCell>
                                            <SiteLogo site={site} />
                                        </TableCell>
                                        <TableCell>{site.name}</TableCell>
                                        <TableCell>{site.url}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </>
                )}
            </Paper>

            <SiteDetailDialog open={dialogOpen} toggle={toggleDialog} refresh={fetch} site={selectedSite} />
            <Dialog fullWidth maxWidth="sm" open={errorDialogOpen} onClose={toggleErrorDialog}>
                <DialogTitle>Oops</DialogTitle>
                <DialogContent dividers>
                    <Typography>Looks like you've maxed out how many sites you can have. Considered upgrading your plan under the profile page.</Typography>
                </DialogContent>
                <DialogActions>
                    <ButtonLink to="/dashboard/profile">Profile</ButtonLink>
                    <Button onClick={toggleErrorDialog} variant="contained" color="secondary">OK</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default Sites;
