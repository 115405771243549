import { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  Button,
  Paper,
  Grid,
  Box,
  CircularProgress,
  Typography,
  DialogActions,
  TextField,
  useMediaQuery
} from "@material-ui/core";
import { Post, PostCategory, PostType } from "common/src/models";
import { CategorySelection } from "../../Common/CategorySelection";
import axios from "../../../Axios";
import Topics from "../../Common/Topics";
import Conclusions from "../../Common/Conclusions";
import TextDivider from '../../Common/TextDivider';
import emptyImage from "../../../Images/empty-state.png";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  paper: {
    height: "90vh",
  },
  query: {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    textAlign: "center",
    // transform: "translate(-50%, -50%)",
    color: "white",
  },
  headerImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  headerImageContainer: {
    width: "100%",
    position: "absolute",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      height: 300,
      top: 56,
    },
    [theme.breakpoints.up("md")]: {
      height: 450,
      top: 64,
    },
    [theme.breakpoints.up("lg")]: {
      height: 600,
      top: 64,
    },
  },
}));



export const CreateWithAI: React.FC<{
  open: boolean;
  toggle: () => void;
  refresh: () => void;
  activeStep: number;
  setActiveStep: (step: number) => void;
}> = ({ open, toggle, activeStep, setActiveStep, children }) => {
  const history = useHistory();
  const classes = useStyles();
  const [textValue, setTextValue] = useState("");
  const [categories, setCategories] = useState<PostCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<PostCategory>();
  const [topic, setTopic] = useState("");
  const [recommendedTopics, setRecommendedTopics] = useState<string[]>([]);
  const [topicLoading, setTopicLoading] = useState(true);
  const [conclusionsLoading, setConclusionsLoading] = useState(true);
  const [recommendedConclusions, setRecommendedConclusions] = useState<
    string[]
  >([]);
  const [conclusion, setConclusion] = useState("");
  const [postLoading, setPostLoading] = useState(true);
  const [post, setPost] = useState<Post>();
  const [image, setImage] = useState("");
  const [stepComplete, setStepComplete] = useState(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (!open) {
      setActiveStep(0);
      setTextValue("");
      setSelectedCategory(undefined);
      setTopic("");
      setRecommendedTopics([]);
      setTopicLoading(true);
      setConclusionsLoading(true);
      setRecommendedConclusions([]);
      setConclusion("");
      setPostLoading(true);
      setPost(undefined);
      setImage("");
      setStepComplete(false);
    }
  }, [open])

  const getImage = async (id: number) => {
    try {
      const post = await axios.get<{ result: Post }>(`/posts/${id}`);
      setImage(post.data.result.thumbnailLg?.url || "");
    } catch (err) {
      console.log("Failed to get post with error:", err);
    }
  };

  const getTopics = async (category: PostCategory) => {
    try {
      const result = await axios.get(
        `/categories/${category.id}/recommendedtopics`
      );
      setRecommendedTopics(result.data.result);
      setTopicLoading(false);
    } catch (err) {
      console.log("Failed to get topics with error:", err);
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      getTopics(selectedCategory);
    }
  }, [selectedCategory]);

  const getConclusions = async (id: number, topic: string) => {
    try {
      const result = await axios.get(`/categories/${id}/conclusions/${topic}`);
      setRecommendedConclusions(result.data.result);
      setConclusionsLoading(false);
    } catch (err) {
      console.log("Failed to get conclusions with error:", err);
    }
  };

  const handleSelection = (Select?: string) => {
    console.log("Select: ", Select);
    // Handle topic selection
    if (Select) {
      if (activeStep === 2) {
        setTopic(Select);
        getConclusions(
          selectedCategory ? selectedCategory.id : categories[0].id,
          Select
        );
      }
      if (activeStep === 3) {
        setConclusion(Select);
        generatePost(Select);
      }
      handleNext();
    } else if (activeStep === 2 && topic) {
      getConclusions(
        selectedCategory ? selectedCategory.id : categories[0].id,
        topic
      );
      handleNext();
    } else if (activeStep === 3 && conclusion) {
      handleNext();
      generatePost();
    } else {
      return;
    }
  };

  const updateCategories = (category: PostCategory, checked: boolean) => {
    if (checked) {
      const i = categories.findIndex((c) => c.id === category.id);
      if (i < 0) {
        setCategories([...categories, category]); // update the categories state
        setSelectedCategory(category); // update the selectedCategory state
        handleNext(); // call the handleNext function
      }
    } else {
      const i = categories.findIndex((c) => c.id === category.id);
      console.log("i", category);
      if (i >= 0) {
        categories.splice(i, 1);
        setCategories([...categories]);
        if (activeStep === 2 && categories.length === 0) {
          setActiveStep(1);
        }
      } else {
        setSelectedCategory(category);
        handleNext();
      }
    }
  };

  const steps = [
    "What's your goal",
    "Choose your category",
    "Topic",
    "Conclusion",
    "Reivew",
  ];

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setCategories([]);
      setActiveStep(0);
      toggle();
      markPostComplete();
    } else {
      setActiveStep(activeStep + 1);
      setStepComplete(false);
      // handleSelection();
    }
  };

  const handleBack = () => {
    console.log(`step: `, activeStep);
    if (activeStep === 2) {
      setCategories([]); // reset categories state when going back from step 3
      setTopicLoading(true);
    }
    if (activeStep === 3) {
      setTopic("");
      setConclusionsLoading(true);
    }
    if (activeStep === 4) {
      setConclusion("");
      setPost(undefined);
      setPostLoading(false);
      setImage('');
    }
    setActiveStep(activeStep - 1);
  };

  const markPostComplete = async () => {
    try {
      if (post) {
        await axios.patch<{ result: Post }>(`/posts/${post.id}`, {
          draft: false
        });
        history.push(`/dashboard/posts/${post.id}`);
      }
    } catch (err) {
      console.log("Failed to mark post complete with error:", err);
    }
  };

  const generatePost = async (conclusionSelection?: string) => {
    if (conclusionSelection) {
      const query = `Write me a blog post in the category ${selectedCategory?.name} about ${topic} with the conclusion ${conclusionSelection}`;
      console.log("query in not else", query);
      try {
        const result = await axios.post<{ job: any; post: Post }>(`/posts`, {
          query: query,
          tags: selectedCategory
            ? [selectedCategory.name]
            : categories.map((t) => t.name),
          categoryIds: selectedCategory
            ? [selectedCategory.id]
            : categories.map((c) => c.id),
          originalText: query,
          type: PostType.query,
        });
        if (result.data.post) {
          setPost(result.data.post);
          setPostLoading(false);
          getImage(result.data.post.id);
        }
      } catch (err) {
        console.log("Failed to get make post with error:", err);
      }
    } else {
      const query = `Write me a blog post in the category ${selectedCategory?.name} about ${topic} with the conclusion ${conclusion}`;
      console.log("query in else", query);
      try {
        const result = await axios.post<{ job: any; post: Post }>(`/posts`, {
          query: query,
          tags: selectedCategory
            ? [selectedCategory.name]
            : categories.map((t) => t.name),
          categoryIds: selectedCategory
            ? [selectedCategory.id]
            : categories.map((c) => c.id),
          originalText: query,
          type: PostType.query,
        });
        if (result.data.post) {
          setPostLoading(false);
          setPost(result.data.post);
          getImage(result.data.post.id);
        }
      } catch (err) {
        console.log("Failed to get make post with error:", err);
      }
    }
  };

  useEffect(() => {
    if (activeStep === 1 && textValue) {
      setStepComplete(true);
    } else if (activeStep === 2 && topic) {
      setStepComplete(true);
    } else if (activeStep === 3 && conclusion) {
      setStepComplete(true);
    }
  }, [activeStep, selectedCategory, topic, conclusion, textValue]);

  return (
    <>
      <DialogContent dividers={activeStep > 0}>
        <form>
          <Stepper
            style={{ padding: 0, paddingTop: 24, paddingBottom: 50 }}
            activeStep={activeStep}
            orientation={isSmall ? "vertical" : "horizontal"}
          >
            {steps.map((label) => (
              <Step key={label} style={{ padding: 0 }}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
          {activeStep === 0 ? (
              <>
                {children}
              </>
          ) : activeStep === 1 ? (
            <div>
              <Grid item xs={12}>
                <Box display="flex" marginBottom={5}>
                  <Box flexGrow={1}>
                    <TextField
                      type="text"
                      variant="outlined"
                      placeholder="What category will your article be in? (Technology, Culinary, etc.)"
                      style={{
                        width: "100%",
                        height: "36px",
                        fontSize: "14px",
                      }}
                      value={textValue}
                      onChange={(event) => setTextValue(event.target.value)}
                      onKeyDown={async (event) => {
                        if (event.key === "Enter") {
                          event.preventDefault(); // prevent the form from submitting
                          const result = await axios.post<{
                            result?: PostCategory;
                          }>(`/categories`, {
                            name: textValue,
                          });
                          if (result.data.result) {
                            updateCategories(result.data.result, false); // call updateCategories with the new category and checked=false
                            setSelectedCategory(result.data.result); // set the selected category to the new category
                            handleNext();
                            setTextValue("");
                          }
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid container>
                  <Grid item xs={12}>
                    <Box style={{ marginTop: 0, marginBottom: 20 }}>
                      <TextDivider>or</TextDivider>
                    </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CategorySelection
                    selectionMode
                    title="Select from your previous categories"
                    clickedCategory={updateCategories}
                  />
                </Grid>
              </Grid>
            </div>
          ) : activeStep === 2 ? (
            <Box>
              <Grid item xs={12}>
                <Box display="flex" marginBottom={5}>
                  <Box flexGrow={1}>
                    <TextField
                      type="text"
                      variant="outlined"
                      placeholder={`What do you want so say about ${selectedCategory?.name}?`}
                      style={{
                        width: "100%",
                        height: "36px",
                        fontSize: "14px",
                      }}
                      value={topic}
                      onChange={(event) => setTopic(event.target.value)}
                      onKeyDown={async (event) => {
                        if (event.key === "Enter") {
                          event.preventDefault(); // prevent the form from submitting
                          handleSelection();
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Box style={{ marginTop: 0, marginBottom: 20 }}>
                    <TextDivider>or</TextDivider>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Topics
                    topics={recommendedTopics}
                    loading={topicLoading}
                    handleTopicSelection={handleSelection}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : activeStep === 3 ? (
            <Box>
              <Grid item xs={12}>
                <Box display="flex" marginBottom={5}>
                  <Box flexGrow={1}>
                    <TextField
                      type="text"
                      variant="outlined"
                      placeholder={`What do you want the conclusion of your article about ${topic} to be?`}
                      style={{
                        width: "100%",
                        height: "36px",
                        fontSize: "14px",
                      }}
                      value={conclusion}
                      onChange={(event) => setConclusion(event.target.value)}
                      onKeyDown={async (event) => {
                        if (event.key === "Enter") {
                          event.preventDefault(); // prevent the form from submitting
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                    <Box style={{ marginTop: 0, marginBottom: 20 }}>
                      <TextDivider>or</TextDivider>
                    </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Conclusions
                    conclusions={recommendedConclusions}
                    loading={conclusionsLoading}
                    handleConclusionSelection={handleSelection}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : activeStep === 4 ? (
            <Grid item xs={12}>
              <Paper style={{ overflow: 'hidden' }}>
                <div>
                  <img
                    className={classes.headerImage}
                    alt="Thumbnail"
                    src={image ? image : emptyImage}
                  />
                  <Box style={{ marginLeft: 15, marginRight: 15, marginTop: 15 }}>
                    <Typography variant="h5">{post?.title}</Typography>
                  </Box>
                </div>
                {!post || postLoading ? (
                  <Box
                    style={{
                      width: "100%",
                      minHeight: "300px",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box>
                      <CircularProgress />
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        Generating Post
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box p={2}>
                    <div
                      dangerouslySetInnerHTML={{ __html: post?.html ?? "" }}
                    />
                  </Box>
                )}
              </Paper>
            </Grid>
          ) : (
            <div>
              <Box></Box>
            </div>
          )}
          </div>
        </form>
      </DialogContent>
      <DialogActions
        style={{
          paddingRight: "24px",
          paddingLeft: "24px",
        }}
      >
        <Box
          display={"flex"}
        >
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            style={
              activeStep === 0
                ? { display: "none" }
                : { display: "block", marginRight: "16px" }
            }
          >
            Back
          </Button>
          {activeStep === 1 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                const result = await axios.post<{
                  result?: PostCategory;
                }>(`/categories`, {
                  name: textValue,
                });
                if (result.data.result) {
                  updateCategories(result.data.result, false);
                  setTextValue("");
                }
              }}
              disabled={!stepComplete}
            >
              Next
            </Button>
          ) : activeStep === 2 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleSelection();
              }}
              disabled={!stepComplete}
            >
              Next
            </Button>
          ) : activeStep === 3 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleSelection();
              }}
              disabled={!stepComplete}
            >
              Next
            </Button>
          ) : activeStep === 4 ? (
            <Button disabled={!post} variant="contained" color="primary" onClick={handleNext}>
              Finish
            </Button>
          ) : (
            <></>
          )}
        </Box>
      </DialogActions>
    </>
  );
};
