import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper,
    CircularProgress,
    Grid,
    Toolbar,
    Typography,
    makeStyles,
    Box,
    IconButton,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions,
    Button,
    TextField,
    Checkbox,
    Tooltip
} from '@material-ui/core';
import {
    AddCircleRounded,
    DeleteRounded
} from '@material-ui/icons';
import { PostCategory } from 'common/src/models';
import axios from '../../Axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AppContext, UserContext } from '../../Contexts';

const useStyles = makeStyles({
    title: {
        paddingLeft: 16,
        paddingRight: 16
    }
});

interface CategoryCreationForm {
    name?: string;
}

const initialValues: CategoryCreationForm = {
    name: ''
};

const Validation = Yup.object().shape({
    name: Yup.string().required('* Required').min(10, 'Your category title should be longer and more detailed'),
});

const CreateCategoryDialog: React.FC<{ open: boolean, toggle: () => void, refresh: () => void }> = ({ open, toggle, refresh }) => {
    const appCtx = useContext(AppContext);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values: CategoryCreationForm) => {
        setLoading(true);
        try {
            const result = await axios.post<{ result?: PostCategory }>(`/categories`, {
                name: values.name
            });
            if (result.data.result) {
                refresh();
                toggle();
            }
            setLoading(false);
        } catch (err: any) {
            if (err.response?.data?.error) {
                console.log(`Failed with error: `, err.response.data.error);
                appCtx.setError(err.response.data.error);
            } else {
                console.log(`Failed with unknown error: `, err);
                appCtx.setError('Oops, something went wrong');
            }

            setLoading(false);
        }

    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={toggle}>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => handleSubmit(values)}
                validationSchema={Validation}
                enableReinitialize={true}
                validateOnBlur
                validateOnChange
                validateOnMount
            >
                {formik => {
                    return (
                        <form
                            onSubmit={(e) => {
                                formik.handleSubmit();
                                e.preventDefault();
                            }}
                        >
                            <DialogTitle>Create Category</DialogTitle>
                            <DialogContent dividers>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            error={(formik.errors.name != null && formik.touched.name)}
                                            helperText={(formik.errors.name && formik.touched.name) && formik.errors.name}
                                            name="name"
                                            label="Name"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={toggle}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                    disabled={!formik.isValid}
                                >
                                    {loading ? <CircularProgress size={25} color="primary" /> : "Save"}
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </Dialog>
    );
};

export interface AllCategoriesProps {
    selectionMode: boolean;
    title?: string;
    clickedCategory?: (category: PostCategory, selected: boolean) => void;
}

export const AllCategories: React.FC<AllCategoriesProps> = ({ title, selectionMode, clickedCategory }) => {
    const appCtx = useContext(AppContext);
    const userCtx = useContext(UserContext);
    const styles = useStyles();
    const [categories, setCategories] = useState<PostCategory[]>([]);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialog] = useState(false);
    const [checked, setChecked] = useState(false);
    const toggleDialog = () => setDialog(!dialogOpen);

    const fetch = useCallback(() => {
        const getData = async () => {
            try {
                setLoading(true);
                const results = await axios.get<{ result: PostCategory[] }>(`/categories`);
                setCategories(results.data.result);
                setLoading(false);
            } catch (err: any) {
                if (err.response?.data?.error) {
                    console.log(`Failed with error: `, err.response.data.error);
                    appCtx.setError(err.response.data.error);
                } else {
                    console.log(`Failed with unknown error: `, err);
                    appCtx.setError('Oops, something went wrong');
                }

                setLoading(false);
            }
        }

        getData();
    }, [userCtx.currentSite]);

    const deleteCategory = async (category: PostCategory) => {
        try {
            await axios.delete<{ result: boolean }>(`/categories/${category.id}`);
            fetch();
        } catch (err: any) {
            if (err.response?.data?.error) {
                console.log(`Failed with error: `, err.response.data.error);
                appCtx.setError(err.response.data.error);
            } else {
                console.log(`Failed with unknown error: `, err);
                appCtx.setError('Oops, something went wrong');
            }
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    return (
        <>
            <Paper>
                {loading && (
                    <Grid container alignItems="center" justify="center" spacing={5}>
                        <Grid item>
                            <CircularProgress color="primary" />
                        </Grid>
                    </Grid>
                )}
                {!loading && (
                    <>
                        <Toolbar className={styles.title}>
                            <Grid container justify="space-between">
                                <Grid item>
                                    <Typography variant="h6" id="tableTitle" component="div">
                                        {title ? title : 'Categories'}
                                    </Typography>
                                </Grid>
                                {!selectionMode && (
                                    <Grid item>
                                        <Tooltip title="Create Category">
                                            <IconButton color="secondary" onClick={toggleDialog}>
                                                <AddCircleRounded />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                )}
                            </Grid>
                        </Toolbar>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Box fontWeight="bold">Name</Box>
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {categories.map(category => (
                                    <TableRow key={`category_${category.id}`}>
                                        <TableCell>{category.name}</TableCell>
                                        <TableCell align="right">
                                            {selectionMode && (
                                                <Checkbox
                                                    value={checked}
                                                    onChange={(e, c) => {
                                                        setChecked(c);
                                                        if (clickedCategory) {
                                                            clickedCategory(category, c);
                                                        }
                                                    }}
                                                />
                                            )}
                                            {!selectionMode && (
                                                <Tooltip title="Delete Category">
                                                    <IconButton onClick={() => deleteCategory(category)}><DeleteRounded /></IconButton>
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </>
                )}
            </Paper>

            <CreateCategoryDialog open={dialogOpen} toggle={toggleDialog} refresh={fetch} />
        </>
    );
};
export default AllCategories;

export interface CategorySelectionDialogProps {
    open: boolean;
    toggle: () => void;
    refresh: () => void;
    postId?: number;
}

export const CategorySelectionDialog: React.FC<CategorySelectionDialogProps> = ({ open, toggle, refresh, postId }) => {
    const appCtx = useContext(AppContext);
    const [categories, setCategories] = useState<PostCategory[]>([]);
    const updateCategories = (category: PostCategory, checked: boolean) => {
        if (checked) {
            const i = categories.findIndex(c => c.id === category.id);
            if (i < 0) {
                categories.push(category);
            }
        } else {
            const i = categories.findIndex(c => c.id === category.id);
            if (i >= 0) {
                categories.splice(i, 1);
                setCategories([...categories]);
            }
        }
    };

    const save = async () => {
        if (categories.length > 0 && postId) {
            try {
                await axios.post<{ result: boolean }>(
                    `/dashboard/posts/${postId}/category`,
                    {
                        categoryIds: categories.map(c => c.id)
                    }
                );
                refresh();
            } catch (err: any) {
                if (err.response?.data?.error) {
                    console.log(`Failed with error: `, err.response.data.error);
                    appCtx.setError(err.response.data.error);
                } else {
                    console.log(`Failed with unknown error: `, err);
                    appCtx.setError('Oops, something went wrong');
                }
            }
        }

        toggle();
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={toggle}>
            <DialogTitle>Select Category</DialogTitle>
            <DialogContent dividers>
                <AllCategories selectionMode clickedCategory={updateCategories} />
            </DialogContent>
            <DialogActions>
                <Button onClick={toggle}>Cancel</Button>
                <Button color="secondary" variant="contained" onClick={save}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};
