import React, { useContext, useState } from 'react';
import {
    Grid,
    TextField,
    makeStyles,
    Button,
    Paper,
    Typography
} from '@material-ui/core';
import { CheckCircleRounded } from '@material-ui/icons';
import { ButtonLink } from '../Common';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from '../../Axios';
import { AppContext } from '../../Contexts';
import { useParams } from 'react-router-dom';

interface ResetPassConfirmationForm {
    newPassword?: string;
    confirmNewPassword?: string;
}

const initialValues: ResetPassConfirmationForm = {
    newPassword: '',
    confirmNewPassword: ''
};

const Validation = Yup.object().shape({
    newPassword: Yup.string().required('* Required'),
    confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], "Passwords don't match").required('* Required'),
});

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: 'rgba(248, 248, 248, 1)',
        width: '100vw',
        height: '100vh'
    },
    resetPassForm: {
        padding: 20,
        [theme.breakpoints.up("md")]: {
            padding: 40
        }
    }
}));

const ResetPasswordConfirmationForm: React.FC<{ success: () => void }> = ({ success }) => {
    const appCtx = useContext(AppContext);
    const params = useParams<{ token?: string }>();

    const handleSubmit = async (values: ResetPassConfirmationForm) => {
        if (!params.token) {
            console.log(`invalid token`);
            return;
        }

        try {
            const result = await axios.post<{ result: boolean }>(`/confirmresetpassword`, { ...values, token: params.token });
            if (result.data.result) {
                success();
            } else {
                appCtx.setError('Failed to reset password');
            }
        } catch (err: any) {
            if (err.response?.data?.error) {
                console.log(`Failed with error: `, err.response.data.error);
                appCtx.setError(err.response.data.error);
            } else {
                console.log(`Failed with unknown error: `, err);
                appCtx.setError('Failed to reset password');
            }
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={Validation}
            enableReinitialize={true}
            validateOnBlur
            validateOnChange
            validateOnMount
        >
            {formik => {
                return (
                    <form
                        onSubmit={(e) => {
                            formik.handleSubmit();
                            e.preventDefault();
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4">Set New Password</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="password"
                                    error={(formik.errors.newPassword != null && formik.touched.newPassword)}
                                    helperText={(formik.errors.newPassword && formik.touched.newPassword) && formik.errors.newPassword}
                                    name="newPassword"
                                    label="New Password"
                                    value={formik.values.newPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="password"
                                    error={(formik.errors.confirmNewPassword != null && formik.touched.confirmNewPassword)}
                                    helperText={(formik.errors.confirmNewPassword && formik.touched.confirmNewPassword) && formik.errors.confirmNewPassword}
                                    name="confirmNewPassword"
                                    label="Confirm New Password"
                                    value={formik.values.confirmNewPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" size="large" fullWidth color="secondary" variant="contained">Submit</Button>
                            </Grid>
                        </Grid>
                    </form>
                );
            }}
        </Formik>
    );
};

export const ResetPasswordConfirmation: React.FC = () => {
    const styles = useStyles();
    const [showSuccess, setShowSuccess] = useState(false);

    return (
        <Grid container alignItems="center" justify="center" className={styles.background}>
            <Grid item xs={11} sm={7} md={6} lg={4}>
                <Paper className={styles.resetPassForm}>
                    {showSuccess && (
                        <Grid container spacing={2} alignItems="center" justify="center">
                            <Grid item>
                                <CheckCircleRounded color="primary" />
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">Success</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ButtonLink size="large" fullWidth to="/login" color="secondary" variant="contained">Login</ButtonLink>
                            </Grid>
                        </Grid>
                    )}
                    {!showSuccess && (
                        <ResetPasswordConfirmationForm success={() => setShowSuccess(true)} />
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};
export default ResetPasswordConfirmation;
