import React, { useEffect, useState, useContext, useRef } from "react";
import { Route, useRouteMatch } from "react-router-dom";
import { PostCreation } from "./PostCreation";
// import { PostCreation as PostCreationOld } from "./PostCreationOld";
import { PostDetail } from "./PostDetail";
import { PostCard } from "../Common";
import {
  Grid,
  IconButton,
  Tooltip,
  Dialog,
  Fade,
  DialogContent,
  TextField,
  Typography,
  DialogTitle,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import {
  AddCircleRounded,
  SearchRounded,
  ClearRounded,
  CreateRounded,
} from "@material-ui/icons";
import { Post } from "common/src/models";
import { ButtonLink } from "../Common";
import axios from "../../Axios";
import { AppContext, UserContext } from "../../Contexts";
import Auth from "../../Auth";

const apiRefreshTime = 25000;
const pageSize = 9;

function TransitionDown(props: any) {
  return <Fade {...props} />;
}

const SearchDialog: React.FC<{
  open: boolean;
  toggle: () => void;
  search: string;
  updatedSearch: (str: string) => void;
}> = ({ open, toggle, search, updatedSearch }) => {
  const searchRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (open && searchRef.current) {
      console.log(`SHOULD focus`);
      searchRef.current.focus();
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      TransitionComponent={TransitionDown}
      keepMounted
      onClose={toggle}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent style={{ padding: 20 }}>
        <TextField
          fullWidth
          inputRef={searchRef}
          focused={open}
          name="search"
          label="Search"
          variant="filled"
          value={search}
          onChange={(e) => updatedSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => updatedSearch("")}>
                <ClearRounded />
              </IconButton>
            ),
          }}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              ev.preventDefault();
              toggle();
            }
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export const Posts: React.FC = () => {
  const appCtx = useContext(AppContext);
  const userCtx = useContext(UserContext);

  const match = useRouteMatch("/dashboard/posts/:id");
  const [search, setSearch] = useState("");
  const [searchOpen, setSearchOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const toggleErrorDialog = () => setErrorDialogOpen(!errorDialogOpen);
  const [postsErrorDialogOpen, setPostsErrorDialogOpen] = useState(false);
  const togglePostsErrorDialog = () =>
    setPostsErrorDialogOpen(!postsErrorDialogOpen);
  const toggleSearch = () => setSearchOpen(!searchOpen);
  const [creationOpen, setCreationOpen] = useState(false);
  // const [creationOldOpen, setCreationOldOpen] = useState(false);
  const toggleCreation = () => setCreationOpen(!creationOpen);
  // const toggleOldCreation = () => setCreationOldOpen(!creationOldOpen);
  const [page, setPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [data, setData] = useState<Post[]>([]);
  const [userHasData, setUserHasData] = useState(false)
  const [monthlyPostCount, setMonthlyPostCount] = useState(0);

  const createPost = () => {
    let allowedPosts = 5;
    if (userCtx.user?.plan) {
      allowedPosts = userCtx.user.plan.postsAllowed;
    }

    if (userCtx.user?.expired) {
      toggleErrorDialog();
    } else if (monthlyPostCount >= allowedPosts) {
      togglePostsErrorDialog();
    } else {
      // const res = window.confirm('Do you want to use the new post creation?')
      // if (res) {
      //   toggleCreation();
      // } else {
      //   toggleOldCreation();
      // }
      toggleCreation();
    }
  };

  const fetch = () => {
    const getData = async () => {
      try {
        if (Auth.isLoggedIn() && userCtx.currentSite) {
          if (search && search.length > 0) {
            const posts = await axios.get<{
              result: Post[];
              totalCount: number;
              monthTotal: number;
            }>(
              `/posts/search/${encodeURI(search)}?offset=${
                (page - 1) * pageSize
              }&limit=${pageSize}`
            );
            setData(
              posts.data.result && posts.data.result.length > 0
                ? posts.data.result
                : []
            );
            setMonthlyPostCount(posts.data.monthTotal);
            setNumberOfPages(Math.ceil(posts.data.totalCount / pageSize));
          } else {
            const posts = await axios.get<{
              result: Post[];
              totalCount: number;
              monthTotal: number;
            }>(`/posts?offset=${(page - 1) * pageSize}&limit=${pageSize}`);
            setData(
              posts.data.result && posts.data.result.length > 0
                ? posts.data.result
                : []
            );
            setMonthlyPostCount(posts.data.monthTotal);
            setNumberOfPages(Math.ceil(posts.data.totalCount / pageSize));
          }

          const hasLoadingPosts = data.find((p) => !p.finished) != null;
          if (hasLoadingPosts) {
            setTimeout(fetch, apiRefreshTime);
          }
        }
      } catch (err: any) {
        if (err.response?.data?.error) {
          console.log(`Failed with error: `, err.response.data.error);
          appCtx.setError(err.response.data.error);
        } else {
          console.log(`Failed with unknown error: `, err);
          appCtx.setError("Oops, something went wrong");
        }
      }
    };

    getData();
  };
  useEffect(() => {
    fetch();
  }, [page, search, userCtx.currentSite]);

  useEffect(() => {
    if (data.length > 0) {
      setUserHasData(true)
    }
  }, [data])

  useEffect(() => {
    if (!match?.isExact) {
      appCtx.setHeader({
        title:
          userCtx.user?.sites && userCtx.user.sites.length > 1
            ? `${userCtx.currentSite?.name ?? ""} Posts`
            : "Posts",
        rightAction: (
          <>
            <Tooltip title="Search" aria-label="search posts">
              <IconButton onClick={toggleSearch} style={{ color: "white" }}>
                <SearchRounded />
              </IconButton>
            </Tooltip>
            <Tooltip title="Create Post" aria-label="create post">
              <IconButton color="secondary" onClick={createPost}>
                <AddCircleRounded />
              </IconButton>
            </Tooltip>
          </>
        ),
      });

      fetch();
    }
  }, [match?.isExact, monthlyPostCount, userCtx.currentSite]);

  return (
    <>
      {match?.isExact ? (
        <Route exact path="/dashboard/posts/:id" component={PostDetail} />
      ) : (
        <>
            <Grid container spacing={3}>
              {userHasData && (
                <>
                  {data.length > 0 ? (
              data.map((post) => (
                <Grid key={`post_${post.id}`} item xs={12} md={4}>
                  <PostCard post={post} />
                </Grid>
              ))
            ) : (
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ height: "100vh" }}
              >
                <Grid item>
                  <CreateRounded
                    fontSize="large"
                    color="disabled"
                    style={{ marginRight: 10 }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="textSecondary">
                    No Post Match Your Search
                  </Typography>
                </Grid>
              </Grid>
              )}
                </>
              )}
              {!userHasData && (
              <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: "100vh" }}
            >
              <Grid item>
                <CreateRounded
                  fontSize="large"
                  color="disabled"
                  style={{ marginRight: 10 }}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1" color="textSecondary">
                  Create Your First Post
                </Typography>
              </Grid>
            </Grid>
              )}
            {numberOfPages > 0 && (
              <Grid item xs={12}>
                <Grid container justify="center" alignContent="center">
                  <Pagination
                    color="secondary"
                    count={numberOfPages}
                    page={page}
                    onChange={(e, p) => {
                      setPage(p);
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      )}

      <PostCreation
        open={creationOpen}
        toggle={toggleCreation}
        refresh={fetch}
      />
      {/* <PostCreationOld
        open={creationOldOpen}
        toggle={toggleOldCreation}
        refresh={fetch}
      /> */}
      <SearchDialog
        open={searchOpen}
        toggle={toggleSearch}
        search={search}
        updatedSearch={(s) => {
          setSearch(s);
        }}
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={errorDialogOpen}
        onClose={toggleErrorDialog}
      >
        <DialogTitle>Oops</DialogTitle>
        <DialogContent dividers>
          <Typography>
            Looks like your plan is expired. Please go to your profile to update
            your plan.
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonLink to="/dashboard/profile">Profile</ButtonLink>
          <Button
            onClick={toggleErrorDialog}
            variant="contained"
            color="secondary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={postsErrorDialogOpen}
        onClose={togglePostsErrorDialog}
      >
        <DialogTitle>Oops</DialogTitle>
        <DialogContent dividers>
          <Typography>
            Looks like you've met your monthly limit on posts. Consider
            upgrading your plan under the profile page.
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonLink to="/dashboard/profile">Profile</ButtonLink>
          <Button
            onClick={togglePostsErrorDialog}
            variant="contained"
            color="secondary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Posts;
