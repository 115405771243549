import React from 'react';
import { 
    Grid, 
    makeStyles
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles({
    footer: {
        width: '100%',
        position: 'absolute',
        bottom: 0,
        left: 0,
        paddingTop: 30,
        paddingBottom: 30,
        backgroundColor: 'rgba(248, 248, 248, 1)'
    },
    footerLink: {
        marginLeft: 10,
        marginRight: 10
    }
});

export const PublicRouteFooter: React.FC = () => {
    const styles = useStyles();

    return (
        <div className={styles.footer}>
            <Grid container justify="center" alignItems="center">
                <Grid item>
                    <Link className={styles.footerLink} color="inherit" underline='none' component={RouterLink} to={`/`}>Home</Link>
                    <Link className={styles.footerLink} color="inherit" underline='none' component={RouterLink} to={`/contact`}>Contact</Link>
                    <Link className={styles.footerLink} color="inherit" underline='none' component={RouterLink} to={`/privacy`}>Privacy Policy</Link>
                </Grid>
            </Grid>
        </div>
    );
}
export default PublicRouteFooter;