import React, { useEffect, useState, useCallback } from "react";
import { User, Site } from "common/src/models";
import axios from "../Axios";
import { useHistory } from "react-router-dom";
import Auth from "../Auth";
import { SiteDetailDialog } from "../Components/Common";
import { CircularProgress } from "@material-ui/core";

export interface UserContextProps {
  user?: User;
  setUser: (u?: User) => void;
  currentSite?: Site;
  setCurrentSite: (s: Site) => void;
  sites: Site[];
}

export const UserContext = React.createContext<UserContextProps>({
  setUser: (e) => {},
  setCurrentSite: (e) => { },
  sites: [],
});

export const UserProvider: React.FC = (props) => {
  const history = useHistory();
  const [user, setUser] = useState<User>();
  const [currentSite, setCurrentSite] = useState<Site>();
  const [sites, setSites] = useState<Site[]>([]);
  const [showSiteDialog, setShowSiteDialog] = useState(false);
  const [loading, setLoading] = useState(true);

  const _setCurrentSite = (site: Site) => {
    console.log(`setting current site to: `, site);
    axios.defaults.headers.common['App-Authorization'] = site.authToken;
    setCurrentSite(site);
  }

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const sites = await axios.get<{ result: Site[] }>(`/sites`);
        setSites(sites.data.result);
        if (!currentSite && sites.data.result.length > 0) {
          _setCurrentSite(sites.data.result[0]);
        }
        setLoading(false);
      } catch (e) {
        console.log(`failed to get sites with error: `, e);
        setLoading(false);
      }
    }
    if (user) {
      getData();
    }
  }, [user]);

  const fetchUser = useCallback(async () => {
    try {
      if (Auth.isLoggedIn()) {
        const userResult = await axios.get<{ result: User }>("/user");
        setUser(userResult.data.result);
      } else {
        console.log(`attempted to fetch user, but user wasn't logged in`);
      }
    } catch (e) {
      console.log(`Failed to get user with error: `, e);
      Auth.logout();
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    console.log("checking for user");
    if (!user && Auth.isLoggedIn()) {
      console.log("no user found");
      fetchUser();
    }

    if (user) {
      console.log("weve got a user", user);
      const token = Auth.token();
      if (token?.appKey) {
        const site = user.sites?.find((s) => s.authToken === token.appKey);
        console.log("found site", site);
        if (site) {
          setCurrentSite(site);
        }
      } else if (user.sites && user.sites.length > 0) {
        const site = user.sites[0];
        console.log("check here", site);
        if (site) {
          Auth.setSite(site.authToken);
          setCurrentSite(site);
        }
      }

      if (!user.sites || user.sites?.length <= 0) {
        setShowSiteDialog(true);
      } else {
        setShowSiteDialog(false);
      }
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        currentSite,
        setCurrentSite: _setCurrentSite,
        sites
      }}
    >
      {loading ? <CircularProgress /> : props.children}

      <SiteDetailDialog
        open={showSiteDialog}
        toggle={() => {}}
        refresh={fetchUser}
        site={undefined}
      />
    </UserContext.Provider>
  );
};

export default UserProvider;
