import React, { useContext } from 'react';
import {
    Grid,
    TextField,
    makeStyles,
    Button,
    Paper,
    Typography
} from '@material-ui/core';
// import { ButtonLink } from '../Common';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from '../../Axios';
import { AppContext } from '../../Contexts';
import { PricingResponse } from 'common/src/models';
import Auth from '../../Auth';
// import { useHistory } from 'react-router-dom';

interface PricingForm {
    email?: string;
    firstName?: string;
    lastName?: string;
    message?: string
}

const initialValues: PricingForm = {
    email: '',
    firstName: '',
    lastName: '',
    message: '',
};

const Validation = Yup.object().shape({
    email: Yup.string().required('* Required'),
});

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: `${theme.palette.primary.main}`,
        // width: '100vw',
        height: '100vh'
    },
    PricingForm: {
        padding: 20,
        [theme.breakpoints.up("md")]: {
            padding: 40
        }
    }
}));

const PricingForm: React.FC = () => {
    const appCtx = useContext(AppContext);
    // const history = useHistory();

    // useEffect(() => {
    //     if (appCtx.loggedIn) {
    //         history.push('/dashboard');
    //     }
    // }, [appCtx.loggedIn]);

    const handleSubmit = async (values: PricingForm) => {
        try {
            const result = await axios.post<PricingResponse>(`/pricing`, {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                message: values.message
            });
            if (result.data.result.token) {
                Auth.login(result.data.result.token, result.data.result.expiresIn, undefined);
                appCtx.setLoggedIn(true);
            }
        } catch (err: any) {
            if (err.response?.data?.error) {
                console.log(`Failed with error: `, err.response.data.error);
                appCtx.setError(err.response.data.error);
            } else {
                console.log(`Failed with unknown error: `, err);
                appCtx.setError('Failed to signup');
            }
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={Validation}
            enableReinitialize={true}
            validateOnBlur
            validateOnChange
            validateOnMount
        >
            {formik => {
                return (
                    <form
                        onSubmit={(e) => {
                            formik.handleSubmit();
                            e.preventDefault();
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4">Pricing</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    error={(formik.errors.email != null && formik.touched.email)}
                                    helperText={(formik.errors.email && formik.touched.email) && formik.errors.email}
                                    name="email"
                                    label="Email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    error={(formik.errors.firstName != null && formik.touched.firstName)}
                                    helperText={(formik.errors.firstName && formik.touched.firstName) && formik.errors.firstName}
                                    name="firstName"
                                    label="First Name"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    error={(formik.errors.lastName != null && formik.touched.lastName)}
                                    helperText={(formik.errors.lastName && formik.touched.lastName) && formik.errors.lastName}
                                    name="lastName"
                                    label="Last Name"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                      </Grid>
                      <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="password"
                                    error={(formik.errors.message != null && formik.touched.message)}
                                    helperText={(formik.errors.message && formik.touched.message) && formik.errors.message}
                                    name="message"
                                    label="Message"
                                    value={formik.values.message}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    multiline
                                    rows={6}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="password"
                                    error={(formik.errors.password != null && formik.touched.password)}
                                    helperText={(formik.errors.password && formik.touched.password) && formik.errors.password}
                                    name="password"
                                    label="Password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="password"
                                    error={(formik.errors.confirmPassword != null && formik.touched.confirmPassword)}
                                    helperText={(formik.errors.confirmPassword && formik.touched.confirmPassword) && formik.errors.confirmPassword}
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid> */}
                            <Grid item xs={12}>
                              <Button type="submit" size="large" fullWidth color="secondary" variant="contained" style={{ textTransform: 'uppercase'}}>get info about pricing</Button>
                            </Grid>
                        </Grid>
                    </form>
                );
            }}
        </Formik>
    );
};

export const Pricing: React.FC = () => {
    const styles = useStyles();

    return (
        <Grid container alignItems="center" justify="center" className={styles.background}>
            <Grid item xs={11} sm={7} md={6} lg={4}>
                <Paper className={styles.PricingForm}>
                    <PricingForm />
                </Paper>
            </Grid>
        </Grid>
    );
};
export default Pricing;
