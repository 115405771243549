import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Drawer as MuiDrawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Tooltip,
  Button,
  // Avatar,
  Menu,
  MenuItem,
  // MenuList,
  Box,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ArrowBackIosRounded as ArrowBackRoundedIcon,
  ExitToAppRounded as ExitAppIcon,
  PersonRounded,
  CompareArrows as CompareArrowsIcon
} from "@material-ui/icons";
import {
  Link as RouterLink,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import { AppContext, UserContext } from "../../Contexts";
import Auth from "../../Auth";
import { Footer, SiteLogo } from "../Common";
import { Profile } from "../Pages";
// import { Site } from "common/src/models";
import { zoomStyle } from '../../Styles/zoom';

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    position: "relative",
    minHeight: "100vh",
  },
  routeContent: {
    paddingBottom: 100,
  },
  listButtonText: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  listButtonIcon: {
    marginLeft: 7,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  listButtonActive: {
    color: theme.palette.secondary.main,
  },
  avatar: {
    width: 25,
    height: 25,
  },
}));

export interface NavRoute {
  name: string;
  icon: React.ReactNode;
  path: string;
  component: React.FC<{}>;
}

const ActionDrawerButton: React.FC<{
  title: string;
  isClosed: boolean;
  icon: React.ReactNode;
  action: (e: Element) => void;
}> = ({ title, isClosed, icon, action }) => {
  const classes = useStyles();
  const zoom = zoomStyle()();

  const renderIcon = isClosed ? (
    <Tooltip placement="right" title={title} aria-label={title}>
      <ListItemIcon className={classes.listButtonIcon}>{icon}</ListItemIcon>
    </Tooltip>
  ) : (
    <ListItemIcon className={classes.listButtonIcon}>{icon}</ListItemIcon>
  );

  return (
    <ListItem
      className={zoom.root}
      onClick={(e) => action(e.currentTarget)}
      style={{ cursor: "pointer" }}
    >
      {renderIcon}
      <ListItemText primary={title} className={classes.listButtonText} />
    </ListItem>
  );
};

const SiteSwitcher: React.FC<{ isClosed: boolean }> = ({ isClosed }) => {
  const userCtx = useContext(UserContext);
  const classes = useStyles();
  const theme = useTheme();
  const zoom = zoomStyle()();
  const [anchorEl, setAnchorEl] = React.useState<HTMLLIElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const icon = (
    <ListItemIcon
      className={classes.listButtonIcon}
      style={{
        position: 'relative'
      }}
    >
      <Box
        style={{
          position: 'absolute',
          top: -24,
          left: -12,
          width: 48,
          height: 48,
          borderRadius: 24,
          backgroundColor: theme.palette.secondary.main,
        }}
      />
      <CompareArrowsIcon
        style={{
          position: 'absolute',
          top: -12,
          left: 0
        }}
      />
    </ListItemIcon>
  )

  return (
    <>
      <ListItem
        className={zoom.root}
        style={{
          cursor: "pointer",
          width: isClosed ? 72 : 'auto',
        }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        {isClosed ? (
          <Tooltip placement="right" title="Change Site" aria-label="change swite">
            {icon}
          </Tooltip>
        ) : icon}
        <ListItemText
          primary="Change Site"
          className={classes.listButtonText}
        />
      </ListItem>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {userCtx.sites?.map((site) => (
          <>
            <MenuItem key={`site_${site.id}`} onClick={() => {
              handleClose();
              userCtx.setCurrentSite(site);
            }}>
              <SiteLogo site={site} />
              <Typography style={{
                marginLeft: 5,
                color: site.id === userCtx.currentSite?.id ?  theme.palette.secondary.main : 'auto'
              }}>
                {site.name}
              </Typography>
            </MenuItem>
          </>
        ))}
      </Menu>
    </>
  );
};

const DrawerButton: React.FC<{ route: NavRoute; isClosed: boolean }> = ({
  route,
  isClosed,
}) => {
  const classes = useStyles();
  const childMatch = useRouteMatch(`${route.path}/*`);
  const rootMatch = useRouteMatch(route.path);
  const zoom = zoomStyle()();

  const renderIcon = isClosed ? (
    <Tooltip placement="right" title={route.name} aria-label={route.name}>
      <ListItemIcon
        className={
          childMatch?.isExact || rootMatch?.isExact
            ? `${classes.listButtonIcon} ${classes.listButtonActive}`
            : classes.listButtonIcon
        }
      >
        {route.icon}
      </ListItemIcon>
    </Tooltip>
  ) : (
    <ListItemIcon
      className={
        childMatch?.isExact || rootMatch?.isExact
          ? `${classes.listButtonIcon} ${classes.listButtonActive}`
          : classes.listButtonIcon
      }
    >
      {route.icon}
    </ListItemIcon>
  );

  return (
    <ListItem className={zoom.root} component={RouterLink} to={route.path}>
      {renderIcon}
      <ListItemText
        primary={route.name}
        className={
          childMatch?.isExact || rootMatch?.isExact
            ? `${classes.listButtonText} ${classes.listButtonActive}`
            : classes.listButtonText
        }
      />
    </ListItem>
  );
};

export const Drawer: React.FC<{ routes: NavRoute[] }> = (props) => {
  const appCtx = useContext(AppContext);
  const userCtx = useContext(UserContext);
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  // const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  // const closeSiteMenu = () => {
  //   setAnchorEl(null);
  // };

  // const selectSite = (site: Site) => {
  //   Auth.setSite(site.authToken);
  //   userCtx.setCurrentSite(site);
  //   setAnchorEl(null);
  // };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const backBtn = () => {
    history.goBack();
  };

  const logout = () => {
    Auth.logout();
    appCtx.setLoggedIn(false);
    history.push("/");
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              {appCtx.header?.actionTitle ? (
                <Button
                  startIcon={<ArrowBackRoundedIcon />}
                  onClick={backBtn}
                  style={{ color: "white" }}
                >
                  {appCtx.header.actionTitle}
                </Button>
              ) : (
                <Typography variant="h6" noWrap style={{ color: "white" }}>
                  {appCtx.header?.title ?? ""}
                </Typography>
              )}
            </Grid>
            <Grid item>{appCtx.header?.rightAction}</Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <MuiDrawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <Grid
          container
          justify="space-between"
          direction="column"
          style={{ height: "100%" }}
        >
          <Grid item>
            <List>
              {userCtx.sites.length > 1 && (
                <SiteSwitcher isClosed={!open} />
              )}
              {props.routes.map((route) => {
                return (
                  <DrawerButton
                    route={route}
                    isClosed={!open}
                    key={route.path}
                  />
                );
              })}
            </List>
          </Grid>
          <Grid item>
            <Grid container direction="column" justify="flex-end">
              <Grid item>
                <List>
                  <DrawerButton
                    route={{
                      name: userCtx.user?.expired
                        ? "Profile/Expired"
                        : "Profile",
                      path: "/dashboard/profile",
                      component: Profile,
                      icon: (
                        <PersonRounded
                          color={userCtx.user?.expired ? "error" : "inherit"}
                        />
                      ),
                    }}
                    isClosed={!open}
                  />
                  <ActionDrawerButton
                    title="Logout"
                    icon={<ExitAppIcon />}
                    action={logout}
                    isClosed={!open}
                  />
                </List>

                {/* {userCtx.user?.sites && userCtx.user.sites.length > 1 && (
                  <>
                    <Divider />
                    <List>
                      <ActionDrawerButton
                        title={userCtx.currentSite?.name ?? ""}
                        icon={
                          userCtx.currentSite ? (
                            <SiteLogo site={userCtx.currentSite} />
                          ) : (
                            <></>
                          )
                        }
                        action={(e) => setAnchorEl(e)}
                        isClosed={!open}
                      />
                    </List>
                    <Menu
                      id="site-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={closeSiteMenu}
                    >
                      <Box
                        style={{
                          paddingLeft: 15,
                          paddingRight: 15,
                          paddingTop: 15,
                        }}
                      >
                        <Typography variant="h6">Sites</Typography>
                      </Box>
                      <MenuList>
                        {userCtx.user?.sites.map((s) => (
                          <MenuItem onClick={() => selectSite(s)}>
                            <ListItemIcon>
                              <SiteLogo site={s} />
                            </ListItemIcon>
                            {s.name}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </>
                )} */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MuiDrawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.routeContent}>{props.children}</div>
        <Footer />
      </main>
    </div>
  );
};
export default Drawer;
