import React, { useContext, useEffect, useState } from 'react';
import {
    Grid,
    Button,
    Link,
    makeStyles
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { AppContext } from '../../Contexts';
import Logo from '../../Images/Logo_White.svg';

export const headerHeight = 76;

const useStyles = makeStyles((theme) => ({
    header: {
        position: 'fixed',
        zIndex: 100,
        top: 0,
        left: 0,
        height: headerHeight,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: 'transparent',
        [theme.breakpoints.up('sm')]: {

        },
    },
    headerColor: {
        backgroundColor: theme.palette.primary.main,
        //transition: 'background-color 0.3s linear'
    },
    linkText: {
      color: 'white'
    }
}));

export const PublicRouteHeader: React.FC = ({ children }) => {
    const styles = useStyles();
    const appCtx = useContext(AppContext);
    const [canCheckHeaderColor, setCanCheckHeaderColor] = useState(true);
    const [headerColor, setHeaderColor] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', (e) => {
            if (canCheckHeaderColor) {
                setCanCheckHeaderColor(false);
                if (window.scrollY > (window.outerHeight - (headerHeight * 2))) {
                    setHeaderColor(true);
                } else {
                    setHeaderColor(false);
                }
                setTimeout(() => setCanCheckHeaderColor(true), 500);
            }
        });
    }, []);

    return (
        <Grid className={`${styles.header} ${headerColor ? styles.headerColor : ''}`} container justify="space-between">
        <Grid item>
        {/* src={`/static/logo/logo-${theme.palette.mode}.svg`} */}
          <img
            width='145'
            height='35'
            src={Logo}
            alt='Composie Logo'
          />
          </Grid>
            {appCtx.loggedIn && (
                <Grid item>
                    <Link underline="none" component={RouterLink} to="/dashboard">
                        <Button variant="contained" color="secondary">Dashboard</Button>
                    </Link>
                </Grid>
            )}
            {!appCtx.loggedIn && (
                <Grid item xs={6}>
                    <Grid container spacing={2} justify="flex-end">
                        <Grid item>
                            <Link underline="none" component={RouterLink} to="/pricing">
                                <Button className={styles.linkText}>Pricing</Button>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link underline="none" component={RouterLink} to="/login">
                                <Button className={styles.linkText} >Login</Button>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link underline="none" component={RouterLink} to="/signup">
                                <Button variant="contained" color="secondary">Sign Up</Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};
export default PublicRouteHeader;
