import React, { useEffect, useCallback, useState, useContext } from "react";
import {
  Grid,
  Tooltip,
  IconButton,
  Paper,
  makeStyles,
  Typography,
  TextField,
  CircularProgress,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Table,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  Chip,
  Toolbar,
  Divider,
  Checkbox,
  useTheme,
  ClickAwayListener,
  // withStyles
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  CreateRounded,
  CheckCircle,
  DeleteRounded,
  AddCircleRounded,
  InsertPhoto,
  TodayRounded,
} from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import axios from "../../Axios";
import {
  PostCategory,
  Post,
  PostTag,
  PostUpdate,
  UploadFileResponse,
  PexelsPhoto,
  Attachment,
} from "common/src/models";
import { ContentBlock, ContentState, convertToRaw, EditorState, SelectionState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AppContext } from "../../Contexts";
import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { CategorySelectionDialog } from "../Common/AllCategories";
import emptyImage from "../../Images/empty-state.png";

const useStyles = makeStyles((theme) => ({
  loadContainer: {
    marginTop: 100,
  },
  query: {
    position: "absolute",
    top: "50%",
    left: "50%",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
    color: "white",
  },
  queryTextField: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    background: "rgba(0,0,0,0.35)",
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%",
    },
  },
  queryTextFieldInput: {
    color: "white",
    textAlign: "center",
  },
  editor: {
    "& .rdw-editor-main": {
      padding: 15,
    },
  },
  readonlyContainer: {
    padding: 20,
  },
  headerImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  headerImageContainer: {
    width: "100%",
    position: "absolute",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      height: 300,
      top: 56,
    },
    [theme.breakpoints.up("md")]: {
      height: 450,
      top: 64,
    },
    [theme.breakpoints.up("lg")]: {
      height: 600,
      top: 64,
    },
  },
  loading: {
    width: "100%",
    position: "absolute",
    zIndex: 5,
    top: 56,
    [theme.breakpoints.up("md")]: {
      top: 64,
    },
  },
  postContent: {
    position: "absolute",
    zIndex: 100,
    marginBottom: 50,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
      top: 56,
      marginTop: 260,
    },
    [theme.breakpoints.up("md")]: {
      top: 64,
      paddingLeft: 35,
      paddingRight: 35,
      marginTop: 410,
    },
    [theme.breakpoints.up("lg")]: {
      top: 64,
      paddingLeft: 40,
      paddingRight: 40,
      marginTop: 560,
    },
  },
  postPaper: {
    minHeight: 300,
  },
  tagsContainer: {
    marginTop: 30,
    marginBottom: 30,
  },
  tagsTitle: {
    marginBottom: 10,
  },
  imageEditBtn: {
    position: "absolute",
    top: 15,
    right: 15,
    color: "white",
  },
  categoriesContainer: {
    marginTop: 30,
  },
  tableToolbar: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  iconColors: {
    color: 'white'
  },
}));

const apiRefreshTime = 25000;

const editorStateForHtml = (html?: string) => {
  const test = htmlToDraft(html ?? "");
  const blocks = test.contentBlocks;
  // console.log(`blocks`, blocks);
  const contentState = ContentState.createFromBlockArray(blocks);

  return EditorState.createWithContent(contentState);
};

const PostScheduleDialog: React.FC<{
  open: boolean;
  toggle: () => void;
  post?: Post;
  changedDate: (date?: Date) => void;
}> = ({ open, toggle, post, changedDate }) => {
  const [date, setDate] = useState<Date | undefined>(undefined);
  useEffect(() => {
    setDate(post?.publishedDate ? new Date(post.publishedDate) : undefined);
  }, [post?.publishedDate]);

  const save = () => {
    if (date) {
      changedDate(date);
      toggle();
    }
  };

  const cancelPublication = () => {
    changedDate(undefined);
    toggle();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={toggle}>
      <DialogTitle>Schedule Post</DialogTitle>
      <DialogContent dividers>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            disableToolbar
            variant="dialog"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Publish Date"
            value={date}
            onChange={(date) => setDate(date as Date)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between">
          <Grid item>
            {post?.publishedDate && (
              <Button onClick={cancelPublication}>Cancel Publication</Button>
            )}
          </Grid>
          <Grid item>
            <Button onClick={toggle}>Cancel</Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={date == null}
              onClick={save}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const PostTagDialog: React.FC<{
  open: boolean;
  toggle: () => void;
  addTag: (tag: string) => void;
}> = ({ open, toggle, addTag }) => {
  const [tag, setTag] = useState("");

  const save = () => {
    if (tag.length > 0) {
      addTag(tag);
    }
    toggle();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={toggle}>
      <DialogTitle>Create Post Tag</DialogTitle>
      <DialogContent dividers>
        <TextField
          fullWidth
          variant="outlined"
          name="tag"
          label="Tag Name"
          value={tag}
          onChange={(e) => setTag(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle}>Cancel</Button>
        <Button variant="contained" color="secondary" onClick={save}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PostImage: React.FC<{ photo: PexelsPhoto, selected: boolean, setSelected: (s: boolean, p: PexelsPhoto) => void }> = ({ photo, selected, setSelected }) => {
  return (
    <Box style={{ position: 'relative' }} onClick={() => setSelected(!selected, photo)}>
      <img style={{ maxHeight: 250, marginRight: 25 }} src={photo.src.large} />
      <Checkbox
        checked={selected}
        size="medium"
        style={{ position: 'absolute', bottom: 15, right: 30 }}
        onChange={e => {
          setSelected(!selected, photo);
        }}
      />
    </Box>
  );
};

const PostImageDialog: React.FC<{
  post: Post | undefined,
  open: boolean;
  toggle: () => void;
  uploadedFile: (updatedPost: Post) => void;
}> = ({ open, post, toggle, uploadedFile }) => {
  // const appCtx = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File>();
  const [loadingPhotos, setLoadingPhotos] = useState(false);
  const [suggestedPhotos, setSuggestedPhotos] = useState<PexelsPhoto[]>([]);
  const [selectedPhoto, setSelectedPhoto] = useState<PexelsPhoto | undefined>();

  useEffect(() => {
    if (open && post && post.tags?.length) {
      const getData = async () => {
        try {
          setLoadingPhotos(true);
          const result = await axios.post<{ result: { tag: string, photos: PexelsPhoto[] }[] }>(`/photos/tags`, {
            tags: post.tags?.map(t => t.tag)
          });
          setLoadingPhotos(false);
          const allPhotos = result.data.result.map(o => o.photos).flat();
          setSuggestedPhotos(allPhotos);
        } catch (e) {
          console.log(`failed to get photos: `, e);
          setLoadingPhotos(false);
        }
      };
      getData();
    }
  }, [open, post]);

  const close = () => {
    setFile(undefined);
    toggle();
  };

  const changedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const updatePhoto = async () => {
    if (post && (selectedPhoto || file)) {
      setLoading(true);
      try {
        let smAttachment: Attachment | undefined;
        let lgAttachment: Attachment | undefined;

        if (file) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("createThumbnail", "true");
          const result = await axios.post<UploadFileResponse>(
            `/photos/upload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (result.data.image) {
            smAttachment = result.data.thumbnail;
            lgAttachment = result.data.image;
          }
        } else if (selectedPhoto) {
          const smResult = await axios.post<{ attachment: Attachment }>(`/photos/attachment`, { url: selectedPhoto.src.large2x });
          const ldResult = await axios.post<{ attachment: Attachment }>(`/photos/attachment`, { url: selectedPhoto.src.original });

          if (smResult.data.attachment) {
            smAttachment = smResult.data.attachment;
          }
          if (ldResult.data.attachment) {
            lgAttachment = ldResult.data.attachment;
          }
        }

        if (smAttachment && lgAttachment) {
          const updatedPost = await axios.patch<{ result: Post }>(`/posts/${post.id}`, {
            thumbnailSmId: smAttachment.id,
            thumbnailLgId: lgAttachment.id
          });

          uploadedFile(updatedPost.data.result);

          setLoading(false);
          close();
          setSelectedPhoto(undefined);
        } else {
          setLoading(false);
        }
      } catch (e) {
        console.log(`failed to update post: `, e);
        setLoading(false);
      }
    }
  };

  // const uploadFile = async () => {
  //   if (file) {
  //     setLoading(true);

  //     const formData = new FormData();
  //     formData.append("file", file);
  //     formData.append("createThumbnail", "true");

  //     try {
  //       const result = await axios.post<UploadFileResponse>(
  //         `/photos/upload`,
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );

  //       // if (result.data.imagePath) {
  //       //   uploadedFile(result.data.imagePath, result.data.thumbnailPath);
  //       // }

  //       setLoading(false);
  //       close();
  //     } catch (err: any) {
  //       if (err.response?.data?.error) {
  //         console.log(`Failed with error: `, err.response.data.error);
  //         appCtx.setError(err.response.data.error);
  //       } else {
  //         console.log(`Failed with unknown error: `, err);
  //         appCtx.setError("Oops, something went wrong");
  //       }

  //       setLoading(false);
  //     }
  //   }
  // };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={close}>
      <DialogTitle>Update Post Image</DialogTitle>
      <DialogContent dividers>
        <Grid container alignItems="center" alignContent="center" spacing={2}>
          {loadingPhotos ? (
            <>
              <Box style={{ width: '100%', display: 'flex' }}>
                <CircularProgress />
              </Box>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          ) : suggestedPhotos.length > 0 ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="caption">Suggestions</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Box style={{ display: 'flex', flexDirection: 'row', overflow: 'scroll' }}>
                    {suggestedPhotos.map(p => (
                      <PostImage
                        key={`img_${p.id}`}
                        photo={p}
                        selected={p.id === selectedPhoto?.id}
                        setSelected={(selected, photo) => {
                          setSelectedPhoto(photo);
                        }}
                      />
                    ))}
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
          ) : null}


          {file && (
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs={2}>
                  <img style={{ width: 60 }} src={URL.createObjectURL(file)} />
                </Grid>
                <Grid item xs={9}>
                  <Typography>{file.name}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Tooltip title="Remove File">
                    <IconButton onClick={() => setFile(undefined)}>
                      <DeleteRounded />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!file && (
            <Grid item xs={12}>
              <Button variant="contained" component="label">
                Add File
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  multiple={false}
                  onChange={changedFile}
                />
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={updatePhoto}
          disabled={!selectedPhoto && !file}
        >
          {loading ? <CircularProgress size={25} color="primary" /> : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
  };

interface HTMLContentBlockProps {
  blockProps: {
    block: ContentBlock,
  }
  selection: SelectionState
}

const HTMLContentBlock: React.FC<HTMLContentBlockProps> = (props) => {
  const theme = useTheme();
  const { block } = props.blockProps;
  const [open, setOpen] = React.useState(false);

  // const HtmlTooltip = withStyles((theme) => ({
  //   tooltip: {
  //     backgroundColor: '#f5f5f9',
  //     color: 'rgba(0, 0, 0, 0.87)',
  //     maxWidth: 220,
  //     fontSize: theme.typography.pxToRem(12),
  //     border: '1px solid #dadde9',
  //     pointerEvents: 'none'
  //   },
  // }))(Tooltip);

  const useStyles = makeStyles({
    tooltip: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 220,
          fontSize: 12,
          border: '1px solid #dadde9',
          pointerEvents: 'none'
        },
  });

  const classes = useStyles();


  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    console.log("Block clicked"); //this is not being logged
    setOpen(true);
  };

  const handleTooltipClose = () => {
    console.log("being closed")
    setOpen(false);
  }




  return (



    <ClickAwayListener onClickAway={handleTooltipClose} >
      <div style={{ border: "1px solid black", padding: "10px" }}
            onClick={handleClick}
      >
      <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open && props.selection.getFocusKey() === block.getKey()}
            disableFocusListener
            disableHoverListener
            title={
              <React.Fragment>
                <Typography color="inherit"><u>{'Re-word with AI'}</u></Typography>
              </React.Fragment>
            }
          placement="top-start"
          classes={{tooltip: classes.tooltip}}
        >
        <p
            style={{
              color: props.selection.getFocusKey() === block.getKey() ? theme.palette.primary.dark : 'inherit',
            }}
        >
            {block.getText()}
        </p>
        </Tooltip>
    </div>
    </ClickAwayListener>
  );
};

export const PostDetail: React.FC = () => {
  const appCtx = useContext(AppContext);
  const styles = useStyles();
  const params = useParams<{ id?: string }>();
  const history = useHistory();

  const [deleteDialog, setDeleteDialog] = useState(false);
  const toggleDeletePost = () => setDeleteDialog(!deleteDialog);
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState<Post>();
  const [loading, setLoading] = useState(false);
  const [scheduleApprovalDialog, setScheduleApprovalDialog] = useState(false);
  const toggleScheduleApprovalDialog = () =>
    setScheduleApprovalDialog(!scheduleApprovalDialog);
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const toggleScheduleDialog = () => setScheduleDialogOpen(!scheduleDialogOpen);
  const [addTagDialogOpen, setAddTagDialogOpen] = useState(false);
  const toggleAddTag = () => setAddTagDialogOpen(!addTagDialogOpen);
  const [updateImageDialogOpen, setUpdateImageDialogOpen] = useState(false);
  const toggleUpdateImageDialog = () =>
    setUpdateImageDialogOpen(!updateImageDialogOpen);
  const [addCategoryDialogOpen, setAddCategoryDialogOpen] = useState(false);
  const toggleAddCategory = () =>
    setAddCategoryDialogOpen(!addCategoryDialogOpen);
  // const [selectedBlock, setSelectedBlock] = useState<string | undefined>(undefined);

  const fetch = useCallback((id: string) => {
    const getData = async () => {
      try {
        setLoading(true);
        const post = await axios.get<{ result: Post }>(`/posts/${Number(id)}`);
        setData(post.data.result);
        setEditorState(editorStateForHtml(post.data.result?.html));
        setLoading(false);

        if (post.data.result && !post.data.result.finished) {
          setTimeout(() => {
            if (params.id) {
              fetch(params.id);
            }
          }, apiRefreshTime);
        }
      } catch (err: any) {
        if (err.response?.data?.error) {
          console.log(`Failed with error: `, err.response.data.error);
          appCtx.setError(err.response.data.error);
        } else {
          console.log(`Failed with unknown error: `, err);
          appCtx.setError("Oops, something went wrong");
        }

        setLoading(false);
      }
    };

    getData();
  }, []);

  const updatePost = useCallback(
    async (d?: Post) => {
      const postData = d ? d : data;

      if (postData) {
        try {
          const model: PostUpdate = {
            query: postData.query,
            html: postData.html,
            thumbnailSmId: postData.thumbnailSm?.id,
            thumbnailLgId: postData.thumbnailLg?.id,
            publishedDate: postData.publishedDate,
            tags: postData.tags?.map((t) => t.tag) ?? [],
          };
          await axios.patch(`/posts/${postData.id}`, model);
        } catch (err: any) {
          if (err.response?.data?.error) {
            console.log(`Failed with error: `, err.response.data.error);
            appCtx.setError(err.response.data.error);
          } else {
            console.log(`Failed with unknown error: `, err);
            appCtx.setError("Oops, something went wrong");
          }
        }
      }
    },
    [data]
  );

  const deletePost = useCallback(async () => {
    if (data) {
      try {
        if (params.id) {
          await axios.delete(`/posts/${params.id}`);
          history.push('/dashboard/posts');
        }
      } catch (err: any) {
        if (err.response?.data?.error) {
          console.log(`Failed with error: `, err.response.data.error);
          appCtx.setError(err.response.data.error);
        } else {
          console.log(`Failed with unknown error: `, err);
          appCtx.setError("Oops, something went wrong");
        }
      }
    }
  }, [data]);

  const removeCategory = useCallback(
    async (category: PostCategory) => {
      if (params.id) {
        try {
          const result = await axios.delete<{ result: boolean }>(
            `/posts/${params.id}/category/${category.id}`
          );
          if (result.data.result) {
            fetch(params.id);
          }
        } catch (err: any) {
          if (err.response?.data?.error) {
            console.log(`Failed with error: `, err.response.data.error);
            appCtx.setError(err.response.data.error);
          } else {
            console.log(`Failed with unknown error: `, err);
            appCtx.setError("Oops, something went wrong");
          }
        }
      }
    },
    [params.id]
  );

  const toggleEditMode = () => {
    if (editMode) {
      updatePost();
    }

    setEditMode(!editMode);
  };

  useEffect(() => {
    if (params.id && !data) {
      fetch(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    appCtx.setHeader({
      actionTitle: "Posts",
      rightAction: (
        <>
          {data && data.finished && (
            <>
              <Tooltip
                title={
                  data?.publishedDate
                    ? `Scheduled for ${format(
                        new Date(data.publishedDate),
                        "MM/dd"
                      )}`
                    : "Schedule Post"
                }
                aria-label="Schedule Post"
              >
                <IconButton
                  className={data.publishedDate ? '' : styles.iconColors}
                  color={data.publishedDate ? 'secondary' : 'inherit'}
                  style={{
                    color: data?.publishedDate ? "theme.secondary" : "white"
                  }}
                  onClick={
                    data?.publishedDate || data.type === "manual"
                      ? toggleScheduleDialog
                      : toggleScheduleApprovalDialog
                  }
                >
                  <TodayRounded />
                </IconButton>
              </Tooltip>

              <Tooltip title={"Delete Post"} aria-label="Delete Post">
                <IconButton className={styles.iconColors} onClick={toggleDeletePost}>
                  <DeleteRounded />
                </IconButton>
              </Tooltip>

              {editMode ? (
                <Tooltip title="Save" aria-label="save">
                  <IconButton color="secondary" onClick={toggleEditMode}>
                    <CheckCircle />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Edit Post" aria-label="edit post">
                  <IconButton className={styles.iconColors} onClick={toggleEditMode}>
                    <CreateRounded />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </>
      ),
    });

    // TODO: Revisit this after removing paragraphs
    // if (data && data.finished === 1 && data.paragraphs && data.paragraphs.length > 0 && (!data.html || data.html == '')) {
    //     const htmlStr = data.paragraphs.map(p => `<p>${p.text}</p>`).join('');
    //     const d = { ...data, html: htmlStr };
    //     setData(d);
    //     updatePost(d);
    // }
  }, [editMode, data]);

  const [editorState, setEditorState] = useState(
    editorStateForHtml(data?.html)
  );


  const handleChange = (state: EditorState) => {
      if (data) {
        const d = {
          ...data,
          html: draftToHtml(convertToRaw(state.getCurrentContent())),
        };
        setData(d);
      }
      const newState = EditorState.forceSelection(state, state.getSelection());
      console.log(state.getSelection().getStartKey());
      console.log("focus", state.getSelection().getHasFocus());

      setEditorState(newState);
  };

  const removeTag = (tag: PostTag) => {
    if (data?.tags) {
      const foundIndex = data.tags.findIndex((t) => t.id === tag.id);
      if (foundIndex >= 0) {
        const newTags = [...data.tags];
        newTags.splice(foundIndex, 1);
        const d = { ...data, tags: newTags };
        setData(d);
        updatePost(d);
      }
    }
  };

  if (!data && loading)
    return (
      <Grid
        container
        spacing={4}
        justify="center"
        alignItems="center"
        className={styles.loadContainer}
      >
        <Grid item>
          <CircularProgress color="primary" />
        </Grid>
      </Grid>
    );

  return (
    <Grid>
      {data && !data.finished && (
        <LinearProgress className={styles.loading} color="secondary" />
      )}

      <div className={styles.headerImageContainer}>
        <img
          className={styles.headerImage}
          alt="Thumbnail"
          src={data?.thumbnailLg ? data.thumbnailLg.url : emptyImage}
        />
        {editMode && (
          // <IconButton
          //   onClick={toggleUpdateImageDialog}
          //   className={styles.imageEditBtn}
          // >
          //   <InsertPhoto />
          // </IconButton>
          <Button
            color="secondary"
            variant="contained"
            className={styles.imageEditBtn}
            onClick={toggleUpdateImageDialog}
          >
            <InsertPhoto />
            <Typography>Change Photo</Typography>
          </Button>
        )}
        {editMode && (
          <TextField
            fullWidth
            variant="filled"
            className={styles.queryTextField}
            InputProps={{
              className: styles.queryTextFieldInput,
            }}
            label="Post Title"
            name="query"
            value={data?.title}
            onChange={(e) => {
              if (data) {
                const d = { ...data, query: e.target.value };
                setData(d);
              }
            }}
          />
        )}
        {!editMode && (
          <Typography className={styles.query} variant="h3">
            {data?.title}
          </Typography>
        )}
      </div>
      <Grid container className={styles.postContent}>
        <Grid item xs={12}>
          {editMode && (
            <Paper className={`${styles.editor} ${styles.postPaper}`}>
              <Editor
                editorState={editorState}
                onEditorStateChange={(s) => handleChange(s)}
                // onBlur={() =>}
                customBlockRenderFunc={(block) => {
                  return {
                    component: HTMLContentBlock,
                    props: {
                      block,
                      // selected: selectedBlock === block.getKey(),
                      // setSelected: (key: string, selected: boolean) => {
                      //   if (selected) {
                      //     setSelectedBlock(key);
                      //   } else if (key === selectedBlock && !selected) {
                      //     setSelectedBlock(undefined);
                      //   }
                      // },
                    },
                  };
                }}
              />
            </Paper>
          )}
          {!editMode && (
            <Paper
              className={`${styles.readonlyContainer} ${styles.postPaper}`}
            >
              {!data || (data && !data.finished) ? (
                <Box
                  style={{
                    width: '100%',
                    minHeight: '300px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Box>
                    <CircularProgress />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">Generating Post</Typography>
                  </Box>
                </Box>
              ): (
                <div dangerouslySetInnerHTML = {{ __html: data?.html ?? "" }} />
              )}
            </Paper>
          )}
        </Grid>

        <Grid item xs={12} className={styles.categoriesContainer}>
          {!editMode && data?.categories && data.categories.length > 0 && (
            <>
              <Typography variant="h6">Categories</Typography>
              {data?.categories?.map((c) => (
                <Chip
                  key={`key_${c.id}`}
                  label={c.name}
                  style={{ marginRight: 7 }}
                />
              ))}
            </>
          )}

          {editMode && (
            <>
              <Paper>
                <Toolbar className={styles.tableToolbar}>
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                      <Typography variant="h6" id="tableTitle" component="div">
                        Categories
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Add Category">
                        <IconButton onClick={toggleAddCategory}>
                          <AddCircleRounded />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Toolbar>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Box fontWeight="bold">Name</Box>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.categories && data.categories.length > 0 && (
                      <>
                        {data.categories.map((category) => (
                          <TableRow key={`category_edit_${category.id}`}>
                            <TableCell>{category.name}</TableCell>
                            <TableCell align="right">
                              <Tooltip title="Remove Category">
                                <IconButton
                                  onClick={() => removeCategory(category)}
                                >
                                  <DeleteRounded />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </Paper>
            </>
          )}
        </Grid>

        <Grid item xs={12} className={styles.tagsContainer}>
          {editMode && (
            <>
              <Paper>
                <Toolbar className={styles.tableToolbar}>
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                      <Typography variant="h6" id="tableTitle" component="div">
                        Tags
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Add Tag">
                        <IconButton onClick={toggleAddTag}>
                          <AddCircleRounded />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Toolbar>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Box fontWeight="bold">Name</Box>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.tags && data.tags.length > 0 && (
                      <>
                        {data.tags.map((tag) => (
                          <TableRow key={`tag_edit_${tag.id}`}>
                            <TableCell>{tag.tag}</TableCell>
                            <TableCell align="right">
                              <Tooltip title="Remove Tag">
                                <IconButton onClick={() => removeTag(tag)}>
                                  <DeleteRounded />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </Paper>
            </>
          )}
          {!editMode && (
            <>
              <Typography variant="h6">Tags</Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {data?.tags?.map(t => (
                  <Chip
                    key={`tag_${t.id}`}
                    label={t.tag}
                    style={{ marginRight: 7 }}
                  />
                ))}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>

      <PostTagDialog
        open={addTagDialogOpen}
        toggle={toggleAddTag}
        addTag={(tag) => {
          if (data) {
            const tags = data.tags ?? [];
            tags.push({ id: -1 * (tags.length + 1), tag: tag } as PostTag);

            const d = { ...data, tags };
            setData(d);
            updatePost(d);
          }
        }}
      />
      <PostImageDialog
        post={data}
        open={updateImageDialogOpen}
        toggle={toggleUpdateImageDialog}
        uploadedFile={(updatedPost) => {
          if (data) {
            setData({ ...updatedPost });
          }
        }}
      />
      <PostScheduleDialog
        open={scheduleDialogOpen}
        toggle={toggleScheduleDialog}
        post={data}
        changedDate={(d) => {
          if (data) {
            const newData = { ...data, publishedDate: d } as Post;
            setData(newData);
            console.log(`update post: `, newData);
            updatePost(newData);
          }
        }}
      />

      <CategorySelectionDialog
        open={addCategoryDialogOpen}
        toggle={toggleAddCategory}
        postId={params.id ? Number(params.id) : undefined}
        refresh={() => {
          if (params.id) {
            fetch(params.id);
          }
        }}
      />

      <Dialog
        fullWidth
        maxWidth="sm"
        open={scheduleApprovalDialog}
        onClose={toggleScheduleApprovalDialog}
      >
        <DialogTitle>Post Review</DialogTitle>
        <DialogContent>
          Have you reviewed the post content? You must review the content before
          scheduling the post for publication
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleScheduleApprovalDialog}>No</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              toggleScheduleApprovalDialog();
              toggleScheduleDialog();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={deleteDialog}
        onClose={toggleDeletePost}
      >
        <DialogTitle>Delete Post</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this post?
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDeletePost}>No</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              deletePost();
              toggleDeletePost();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
