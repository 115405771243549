import { makeStyles } from "@material-ui/core/styles";

export const zoomStyle = (level?: number) => {
  return makeStyles({
    root: {
      transition: "transform 0.15s ease-in-out",
      "&:hover": { transform: `scale3d(${level ?? 1.05}, ${level ?? 1.05}, 1)` },
    },
  })
};

export const zoomFadedStyle = (level?: number, faded?: number) => {
  return makeStyles({
    root: {
      transition: "transform 0.15s ease-in-out",
      "&:hover": {
        transform: `scale3d(${level ?? 1.05}, ${level ?? 1.05}, 1)`,
        opacity: `${faded ?? 0.5}`
      },
    },
  })
};
