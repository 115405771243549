import { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  Box,
  Typography,
  CircularProgress,
  DialogActions,
  TextField,
  useMediaQuery
} from "@material-ui/core";
import { Post, PostCategory } from "common/src/models";
import { CategorySelection } from "../../Common/CategorySelection";
import axios from "../../../Axios";
import TextDivider from '../../Common/TextDivider';
import { useHistory } from "react-router-dom";

export const CreateManually: React.FC<{
  open: boolean;
  toggle: () => void;
  refresh: () => void;
  activeStep: number;
  setActiveStep: (step: number) => void;
}> = ({ open, toggle, activeStep, setActiveStep, children }) => {
  const history = useHistory();
  const [textValue, setTextValue] = useState("");
  const [categories, setCategories] = useState<PostCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<PostCategory>();
  const [topic, setTopic] = useState("");
  const [conclusion, setConclusion] = useState("");
  const [stepComplete, setStepComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (!open) {
      setActiveStep(0);
      setTextValue("");
      setSelectedCategory(undefined);
      setTopic("");
      setConclusion("");
      setStepComplete(false);
    }
  }, [open])

  const updateCategories = (category: PostCategory, checked: boolean) => {
    setIsLoading(true);
    if (checked) {
      const i = categories.findIndex((c) => c.id === category.id);
      if (i < 0) {
        setCategories([...categories, category]); // update the categories state
        setSelectedCategory(category); // update the selectedCategory state
        handleNext(category); // call the handleNext function
      }
    } else {
      const i = categories.findIndex((c) => c.id === category.id);
      console.log("i", category);
      if (i >= 0) {
        categories.splice(i, 1);
        setCategories([...categories]);
        if (activeStep === 2 && categories.length === 0) {
          setActiveStep(1);
        }
      } else {
        setSelectedCategory(category);
        handleNext(category);
      }
    }
  };

  const steps = [
    "What's your goal",
    "Choose your category",
  ];

  const handleNext = (category?: PostCategory) => {
      generatePost(category);
  };

  const handleBack = () => {
    console.log(`step: `, activeStep);
    if (activeStep === 2) {
      setCategories([]); // reset categories state when going back from step 3
      // setTopicLoading(true);
    }
    if (activeStep === 3) {
      setTopic("");
      // setConclusionsLoading(true);
    }
    setActiveStep(activeStep - 1);
  };

  const markPostComplete = async (post: Post) => {
    try {
      if (post) {
        await axios.patch<{ result: Post }>(`/posts/${post.id}`, {
          draft: false
        });
        history.push(`/dashboard/posts/${post.id}`);
      }
    } catch (err) {
      console.log("Failed to mark post complete with error:", err);
    }
  };

  const generatePost = async (category?: PostCategory) => {

      // const query = `Write me a blog post in the category ${selectedCategory?.name} about ${topic} with the conclusion ${conclusion}`;
      const query = `Write me a blog post in the category ${category?.name}`;
      console.log("query in else", query);
    try {
        const result = await axios.post<{ job: any; post: Post }>(`/posts`, {
          query: '',
          tags: category
            ? [category.name]
            : categories.map((t) => t.name),
          categoryIds: category
            ? [category.id]
            : categories.map((c) => c.id),
          originalText: category? category.name: '',
          type: 'manual'
        });
      if (result.data.post) {
          toggle();
          markPostComplete(result.data.post);
        }
      } catch (err) {
        console.log("Failed to get make post with error:", err);
      }

  };

  useEffect(() => {
    if (activeStep === 1 && textValue) {
      setStepComplete(true);
    } else if (activeStep === 2 && topic) {
      setStepComplete(true);
    } else if (activeStep === 3 && conclusion) {
      setStepComplete(true);
    }
  }, [activeStep, selectedCategory, topic, conclusion, textValue]);

  return (
    <>
      <DialogContent dividers={activeStep > 0}>
        <form>
          <Stepper
            style={{ padding: 0, paddingTop: 24, paddingBottom: 50 }}
            activeStep={activeStep}
            orientation={isSmall ? "vertical" : "horizontal"}
          >
            {steps.map((label) => (
              <Step key={label} style={{ padding: 0 }}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>

          {isLoading? (

          <Box
            style={{
              width: "100%",
              minHeight: "300px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box>
              <CircularProgress />
            </Box>
            <Box>
              <Typography variant="subtitle2">
                Please wait...
              </Typography>
            </Box>
              </Box>

            ) : (
                <>
                  {activeStep === 0 ? (
                      <>
                        {children}
                      </>
                    ) : activeStep === 1 ? (
                      <div>
                        <Grid item xs={12}>
                          <Box display="flex" marginBottom={5}>
                            <Box flexGrow={1}>
                              <TextField
                                type="text"
                                variant="outlined"
                                placeholder="What category will your article be in? (Technology, Culinary, etc.)"
                                style={{
                                  width: "100%",
                                  height: "36px",
                                  fontSize: "14px",
                                }}
                                value={textValue}
                                onChange={(event) => setTextValue(event.target.value)}
                                onKeyDown={async (event) => {
                                  if (event.key === "Enter") {
                                    event.preventDefault(); // prevent the form from submitting
                                    const result = await axios.post<{
                                      result?: PostCategory;
                                    }>(`/categories`, {
                                      name: textValue,
                                    });
                                    if (result.data.result) {
                                      updateCategories(result.data.result, false); // call updateCategories with the new category and checked=false
                                      setTextValue("");
                                    }
                                  }
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                              <Box style={{ marginTop: 0, marginBottom: 20 }}>
                                <TextDivider>or</TextDivider>
                              </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <CategorySelection
                              selectionMode
                              title="Select from your previous categories"
                              clickedCategory={updateCategories}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    ) : activeStep === 2 ? (
                      <Box>
                        <Grid item xs={12}>
                          <Box display="flex" marginBottom={5}>
                            <Box flexGrow={1}>
                              <TextField
                                type="text"
                                variant="outlined"
                                multiline
                                rowsMax={4}
                                placeholder={`Existing article text...`}
                                style={{
                                  width: "100%",
                                  height: "36px",
                                  fontSize: "14px",
                                }}
                                value={topic}
                                onChange={(event) => setTopic(event.target.value)}
                                onKeyDown={async (event) => {
                                  if (event.key === "Enter") {
                                    event.preventDefault(); // prevent the form from submitting
                                    // handleSelection();
                                  }
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Box>
                    ) : (
                      <div>
                        <Box></Box>
                      </div>
                    )}
                </>
          )}




          </div>
        </form>
      </DialogContent>
      <DialogActions
        style={{
          paddingRight: "24px",
          paddingLeft: "24px",
        }}
      >
        <Box
          display={"flex"}
        >
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            style={
              activeStep === 0
                ? { display: "none" }
                : { display: "block", marginRight: "16px" }
            }
          >
            Back
          </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                const result = await axios.post<{
                  result?: PostCategory;
                }>(`/categories`, {
                  name: textValue,
                });
                if (result.data.result) {
                  updateCategories(result.data.result, false);
                  setTextValue("");
                }
              }}
              disabled={!stepComplete || isLoading}
            >
              Next
            </Button>

        </Box>
      </DialogActions>
    </>
  );
};
