import React, { useState } from 'react';
import {
  Grid,
  makeStyles,
  Typography,
  Button,
  Box,
  // Icon
} from '@material-ui/core';
import Typist from 'react-typist';
import {
  TrendingUpRounded,
  ExpandMoreRounded,
  InsertPhotoRounded,
  KeyboardArrowRightRounded,
  TodayRounded,
  BrushRounded,
  AddCircleRounded
} from '@material-ui/icons';
import psychology from '../../Images/psychology.svg';
import smartButton from '../../Images/smart_button.svg';
import Logo from '../../Images/Logo_White.svg';

const productName = 'Composie';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    // paddingBottom: 100
  },
  fullScreenContainer: {
    width: '100%',
    height: '100vh'
  },
  sectionContainer: {
    paddingTop: 30,
    paddingBottom: 30,
    [theme.breakpoints.up("md")]: {
      paddingTop: 70,
      paddingBottom: 70,
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: 50,
      paddingBottom: 100,
    },
  },
  sectionTitle: {
    paddingBottom: 30,
    [theme.breakpoints.up("md")]: {
      paddingBottom: 70,
    },
    [theme.breakpoints.up("lg")]: {
      paddingBottom: 50,
    },
  },
  cta: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main
  },
  ctaTitle: {
    color: 'white'
  },
  ctaSubtitle: {
    color: 'transparent'
  },
  downArrow: {
    position: 'absolute',
    left: '50%',
    bottom: 30,
    transform: 'translateX(-50%)',
    fontSize: 50,
    color: 'white'
  },
  theProduct: {
    backgroundColor: 'white'
  },
  howItWorks: {
    backgroundColor: 'rgba(248, 248, 248, 1)'
  },
  marginContainer: {
    marginLeft: 20,
    marginRight: 20,
    [theme.breakpoints.up("md")]: {
      marginLeft: 70,
      marginRight: 70,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: 100,
      marginRight: 100,
    },
  },
  howItWorksArrowContainer: {
    position: 'relative'
  },
  howItWorksArrow: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 50,
    opacity: 0,
    color: 'rgba(178,178,178,1)',
    [theme.breakpoints.up("md")]: {
      opacity: 1
    },
    [theme.breakpoints.up("lg")]: {
      opacity: 1
    },
    [theme.breakpoints.up("xl")]: {
      opacity: 1
    },
  },
  benefits: {
    backgroundColor: 'white'
  },
  pricing: {
    backgroundColor: 'blue'
  },
  reviews: {
    backgroundColor: 'red'
  },
  showAnimated: {
    color: 'rgba(245,245,245,1)',
    transition: 'color 0.7s linear',
    transitionDelay: '0.5s'
  },
  showImageTest: {
    opacity: 0
  },
  showImage: {
    opacity: 100,
    transition: 'opacity 0.7s linear',
    transitionDelay: '0.5s'
  },
  showButton: {
    opacity: 1,
    transition: 'opacity 0.7s linear',
    transitionDelay: '0.5s'
  },
  hideButton: {
    opacity: 0
  },
  howItWorksIcon: {
    fontSize: 70,
    width: 70,
    marginBottom: 25
  },
  benefitIcon: {
    fontSize: 40,
    width: 40,
    color: 'rgba(178,178,178,1)',
  }
}));

export const Home: React.FC = () => {
  const styles = useStyles();
  const [showSubtitle, setShowSubtitle] = useState(false);

  return (
    <div className={styles.container}>
      <Grid className={`${styles.cta} ${styles.fullScreenContainer}`} container justify="center" alignItems="center">
        <Grid item xs={10} md={8} lg={5}>
          <Grid container spacing={2}>
            <Grid item>
              <Typist cursor={{ show: false }} onTypingDone={() => setShowSubtitle(true)}>
                <Typography className={styles.ctaTitle} variant="h3">The future of blog post writing is here.</Typography>
              </Typist>
            </Grid>
            <Grid item>
              <Grid container justify="flex-start">
                <Grid item xs={10}>
                  <Typography className={`${styles.ctaSubtitle} ${showSubtitle ? styles.showAnimated : ''}`} variant="h6">
                    Automatically generate blog posts using AI then deploy directly to your website to boost your search engine rankings
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className={showSubtitle ? styles.showButton : styles.hideButton}>
                <Button style={{ marginTop: 20 }} size="large" variant="contained" color="secondary">Learn More</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10} md={8} lg={5}>
          <Grid container spacing={2} justify='center' className={`${styles.showImageTest} ${showSubtitle ? styles.showImage : ''}`}>
          <img
            width='400'
            height='100'
            src={Logo}
            alt='Composie Logo'
          />
          </Grid>
        </Grid>
        <ExpandMoreRounded className={styles.downArrow} />
      </Grid>
      {/* <Grid className={`${styles.theProduct} ${styles.sectionContainer}`} container>

      </Grid> */}
        <Grid className={`${styles.howItWorks} ${styles.sectionContainer}`} container justify="center">
          <Typography variant="h4" className={styles.sectionTitle}>How it works</Typography>
            <Box className={styles.marginContainer}>
              <Grid container spacing={3} justify="space-around">
                <Grid item xs={12} sm={12} md={3} style={{ textAlign: 'center' }}>
                  <img src={psychology} className={styles.howItWorksIcon} />
                  <Typography variant="h6" align="center">Generate Blog Posts Using AI</Typography>
                  <Typography variant="body1" align="center">Simply enter the name for the blog post you'd like to generate and let {productName} create all of the content with automatically selected beautiful images to be included in your post</Typography>
                </Grid>
                <Grid item xs={1} className={styles.howItWorksArrowContainer}>
                  <KeyboardArrowRightRounded className={styles.howItWorksArrow} />
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ textAlign: 'center' }}>
                  <img src={smartButton} className={styles.howItWorksIcon} />
                  <Typography variant="h6" align="center">Automatically Deploy To Your Website</Typography>
                  <Typography variant="body1" align="center">Schedule your posts to be deployed to your website automatically using our intuitive SDK</Typography>
                </Grid>
                <Grid item xs={1} className={styles.howItWorksArrowContainer}>
                  <KeyboardArrowRightRounded className={styles.howItWorksArrow} />
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ textAlign: 'center' }}>
                  <TrendingUpRounded style={{ color: 'rgba(178,178,178,1)' }} className={styles.howItWorksIcon} />
                  <Typography variant="h6" align="center">Boost Your SEO</Typography>
                  <Typography variant="body1" align="center">Boost your website search engine rankings (SEO) by having regularly scheduled, automatically deployed and automatically generated content. Search engines love original blog post content.</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
      {/* <Grid className={`${styles.reviews} ${styles.sectionContainer}`} container>

      </Grid> */}
      <Grid className={`${styles.benefits} ${styles.sectionContainer}`} container justify="center" alignContent="center" alignItems="center">
        <Grid item>
          <Typography variant="h4" className={styles.sectionTitle}>The Benefits</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box className={styles.marginContainer}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <Grid container>
                  <Grid item xs={2}>
                    <img src={psychology} className={styles.benefitIcon} />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="h6">Create Content With AI</Typography>
                    <Typography variant="body1">Automatically create blog content using AI by just entering the name of the blog post</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Grid container>
                  <Grid item xs={2}>
                    <TrendingUpRounded fontSize="large" className={styles.benefitIcon} />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="h6">Boost Your SEO</Typography>
                    <Typography variant="body1">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Grid container>
                  <Grid item xs={2}>
                    <InsertPhotoRounded fontSize="large" className={styles.benefitIcon} />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="h6">Automatically Select Images</Typography>
                    <Typography variant="body1">Automatically create blog content using AI by just entering the name of the blog post</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Grid container>
                  <Grid item xs={2}>
                    <AddCircleRounded fontSize="large" className={styles.benefitIcon} />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="h6">Create Content Manually</Typography>
                    <Typography variant="body1">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Grid container>
                  <Grid item xs={2}>
                    <TodayRounded fontSize="large" className={styles.benefitIcon} />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="h6">Schedule Your Blog Posts</Typography>
                    <Typography variant="body1">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Grid container>
                  <Grid item xs={2}>
                    <BrushRounded fontSize="large" className={styles.benefitIcon} />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="h6">Use Our Inuitive Editor</Typography>
                    <Typography variant="body1">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {/* <Grid className={`${styles.pricing} ${styles.sectionContainer}`} container>

      </Grid> */}
    </div>
  );
};
export default Home;
