import React from 'react';
import { Box, CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import {
  ArrowForwardRounded
} from '@material-ui/icons';

interface Props {
  topics: string[];
  loading: boolean;
  handleTopicSelection: (topicSelect?: string) => void
}

const Topics: React.FC<Props> = ({ topics, loading, handleTopicSelection }) => {

  const handleClick = (topic: string) => {
    handleTopicSelection(topic)
  }


  return (
    // <Box>
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Box fontWeight="bold">Suggested Topics</Box>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        {loading && (
          <TableBody>
            <TableCell colSpan={2}>
              <Box
                style={{
                  width: "100%",
                  minHeight: "300px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <CircularProgress />
                </Box>
                <Box>
                  <Typography variant="subtitle2">Generating Topics</Typography>
                </Box>
              </Box>
            </TableCell>
          </TableBody>
        )}

        {!loading && topics.length !== 0 && (
          <TableBody>
            {topics.map((topic, index) => (
              <TableRow key={index} style={{ cursor: 'pointer' }}>
                <TableCell
                  onClick={() => {
                    handleClick(topic);
                  }}
                >
                  {topic}
                </TableCell>
                <TableCell align='right'>
                  <ArrowForwardRounded />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </Paper>
    // </Box>
  );
}

export default Topics;
