import React, { useEffect, useContext, useCallback, useState } from "react";
import { AppContext, UserContext } from "../../Contexts";
import { Paper, useTheme } from "@material-ui/core";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  MonthView,
  Appointments,
  TodayButton,
  Toolbar,
  DateNavigator,
} from "@devexpress/dx-react-scheduler-material-ui";
import { Post } from "common/src/models";
import axios from "../../Axios";
import { startOfDay, endOfDay } from "date-fns";
import { useHistory } from "react-router-dom";

const ScheduleContext = React.createContext<{
  selectedPostId?: number;
  setSelectedPostId: (id?: number) => void;
}>({
  setSelectedPostId: (e) => {},
});

const Appointment: React.FC<Appointments.AppointmentProps> = (props) => {
  const theme = useTheme();
  const scheduleCtx = useContext(ScheduleContext);

  return (
    <Appointments.Appointment
      {...props}
      style={{
        backgroundColor: theme.palette.secondary.main,
      }}
      onClick={(e) => {
        console.log(`clicked appointment: `, e);
        if (e.data.id) {
          scheduleCtx.setSelectedPostId(e.data.id);
        }
      }}
    >
      {props.children}
    </Appointments.Appointment>
  );
};

export const ScheduleInner: React.FC = () => {
  const scheduleCtx = useContext(ScheduleContext);
  const userCtx = useContext(UserContext);
  const appCtx = useContext(AppContext);
  const history = useHistory();
  const currentDate = new Date();
  const [data, setData] = useState<Post[]>([]);

  const fetch = useCallback(() => {
    const getData = async () => {
      try {
        const posts = await axios.get<{ result: Post[]; totalCount: number }>(
          `/posts?offset=0&limit=2000`
        );
        setData(
          posts.data.result && posts.data.result.length > 0
            ? posts.data.result
            : []
        );
      } catch (err: any) {
        if (err.response?.data?.error) {
          console.log(`Failed with error: `, err.response.data.error);
          appCtx.setError(err.response.data.error);
        } else {
          console.log(`Failed with unknown error: `, err);
          appCtx.setError("Oops, something went wrong");
        }
      }
    };

    getData();
  }, []);

  useEffect(() => {
    appCtx.setHeader({
      title: userCtx.sites.length > 1 ? `${userCtx.currentSite?.name} Schedule` : 'Schedule',
    });

    fetch();
  }, [userCtx.currentSite]);

  useEffect(() => {
    if (scheduleCtx.selectedPostId) {
      history.push(`/dashboard/posts/${scheduleCtx.selectedPostId}`);
      scheduleCtx.setSelectedPostId(undefined);
    }
  }, [scheduleCtx.selectedPostId]);

  return (
    <Scheduler
      data={data
        .filter((p) => p.publishedDate)
        .map((p) => {
          return {
            id: p.id,
            title: p.title,
            startDate: startOfDay(new Date(p.publishedDate ?? "")),
            endDate: endOfDay(new Date(p.publishedDate ?? "")),
          };
        })}
    >
      <ViewState defaultCurrentDate={currentDate} />
      <MonthView />
      <Toolbar />
      <DateNavigator />
      <TodayButton />
      <Appointments appointmentComponent={Appointment} />
    </Scheduler>
  );
};

export const Schedule: React.FC = () => {
  const [selectedPostId, setSelectedPostId] = useState<number>();
  return (
    <Paper>
      <ScheduleContext.Provider value={{ selectedPostId, setSelectedPostId }}>
        <ScheduleInner />
      </ScheduleContext.Provider>
    </Paper>
  );
};

export default Schedule;
