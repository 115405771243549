import React, { useState, useRef, useContext } from 'react';
import { SiteDetail, SiteDetailHandle } from '../Common';
import { Site } from 'common/src/models';
import {
    CircularProgress,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions,
    Button,
    Typography,
    Grid
} from '@material-ui/core';
import { UserContext } from '../../Contexts';

export const SiteDetailDialog: React.FC<{ open: boolean, toggle: () => void, refresh: (s: Site) => void, site?: Site }> = ({ open, toggle, refresh, site }) => {
    const userCtx = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const siteDetail = useRef<SiteDetailHandle | null>(null);

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={toggle}>
            <DialogTitle>{site ? `Edit ${site.name}` : 'Create Site'}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="body1" color="textSecondary">
                            Please enter your website's information in order to get started
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <SiteDetail 
                            ref={siteDetail}
                            site={site} 
                            updatedLoading={setLoading}
                            updatedIsValid={setIsValid}
                            refresh={(s) => {
                                toggle();
                                refresh(s);
                            }} 
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {userCtx.user?.sites && userCtx.user.sites.length > 0 && (
                    <Button 
                        onClick={toggle}
                    >
                        Cancel
                    </Button>
                )}
                <Button 
                    onClick={siteDetail.current?.triggerSubmit}
                    color="secondary" 
                    variant="contained" 
                    disabled={!isValid}
                >
                    {loading ? <CircularProgress size={25} color="primary" /> : "Save"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default SiteDetailDialog;