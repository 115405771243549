import { addMinutes } from 'date-fns';
import axios from './Axios';

interface TokenStore {
    token: string;
    appKey?: string;
    expirationDate: Date;
}

export class Auth {

    private static authKey = `writer_auth`;

    static setHeaders() {
        const tokenStr = localStorage.getItem(Auth.authKey);

        if (tokenStr && tokenStr.length > 0) {
            const token = JSON.parse(tokenStr) as TokenStore;

            axios.defaults.headers.common['Authorization'] = `Bearer ${token.token}`;
            // axios.defaults.headers.common['App-Authorization'] = token.appKey;
        }
    }

    static token() {
        if (Auth.isLoggedIn()) {
            const tokenStr = localStorage.getItem(Auth.authKey);

            if (tokenStr && tokenStr.length > 0) {
                const token = JSON.parse(tokenStr) as TokenStore;

                return token;
            }
        }

        return undefined;
    }

    static isLoggedIn() {
        const tokenStr = localStorage.getItem(Auth.authKey);

        if (tokenStr && tokenStr.length > 0) {
            const token = JSON.parse(tokenStr) as TokenStore;

            if (token.token && token.token.length > 0 && token.expirationDate && new Date() < new Date(token.expirationDate)) {
                return true;
            }
        }

        localStorage.removeItem(Auth.authKey);

        return false;
    }

    static hasSite() {
        const tokenStr = localStorage.getItem(Auth.authKey);

        if (tokenStr && tokenStr.length > 0) {
            const token = JSON.parse(tokenStr) as TokenStore;

            if (token.appKey && token.appKey.length > 0) {
                return true;
            }
        }

        return false;
    }

    static login(token: string, expiresIn: number, appKey?: string) {
        const expirationDate = addMinutes(new Date(), expiresIn);
        const store = { token, expirationDate, appKey } as TokenStore;
        localStorage.setItem(Auth.authKey, JSON.stringify(store));

        Auth.setHeaders();
    }

    static setSite(appToken: string) {
        const token = Auth.token();
        if (token) {
            const store = { token: token.token, expirationDate: token.expirationDate, appKey: appToken } as TokenStore;
            localStorage.setItem(Auth.authKey, JSON.stringify(store));
            Auth.setHeaders();
        }
    }

    static logout() {
        localStorage.removeItem(Auth.authKey);
    }

}
export default Auth;
