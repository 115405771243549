import React, { useEffect, useContext } from "react";
import { AppContext, UserContext } from "../../Contexts";
import { AllCategories } from "../Common/AllCategories";

export const Categories: React.FC = () => {
  const appCtx = useContext(AppContext);
  const userCtx = useContext(UserContext);

  useEffect(() => {
    appCtx.setHeader({
      title: userCtx.sites.length > 1 ? `${userCtx.currentSite?.name} Categories` : 'Categories',
    });
  }, [userCtx.currentSite]);

  return (
    <>
      <AllCategories selectionMode={false} />
    </>
  );
};
export default Categories;
