import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  Icon,
  Grid,
  useMediaQuery
} from "@material-ui/core";
import { CreateWithAI } from '../Common/PostCreation/CreateWithAI';
import { CreateByReword } from '../Common/PostCreation/CreateByReword';
import { CreateManually } from '../Common/PostCreation/CreateManually';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AIIcon from '../../Images/create-with-ai.svg';
import ManualIcon from '../../Images/create-manually.svg';
import RewordIcon from '../../Images/create-reword.svg';
import { zoomFadedStyle } from "../../Styles/zoom";
import clsx from "clsx";

const postTypeStyle = makeStyles({
  root: {
    cursor: "pointer",
    textAlign: 'center'
  },
  image: {
    width: '100%',
    height: '100%'
  }
})

const PostTypeButton: React.FC<{ src: string, title: string, onClick: () => void }> = ({ src, title, onClick }) => {
  const zoom = zoomFadedStyle(1.05, 0.9)();
  const styles = postTypeStyle();

  return (
    <Box className={clsx(styles.root, zoom.root)} onClick={onClick}>
      <Icon style={{ width: 100, height: 100, display: 'block', margin: '0 auto' }}>
        <img src={src} className={styles.image} />
      </Icon>
      <Typography variant="button">{title}</Typography>
    </Box>
  );
};

const PostCreationTypeSelection: React.FC<{ handleCreateAI: () => void, handleReword: () => void, handleManual: () => void }> = (props) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box style={{ paddingTop: 30, paddingBottom: 30 }}>
      <Grid container justify={isSmall ? "center" : "space-evenly"} spacing={isSmall ? 4 : undefined}>
        <Grid item xs={isSmall ? 12 : undefined}>
          <PostTypeButton title="Create with AI" src={AIIcon} onClick={props.handleCreateAI} />
        </Grid>
        <Grid item xs={isSmall ? 12 : undefined}>
          <PostTypeButton title="Reword with AI" src={RewordIcon} onClick={props.handleReword} />
        </Grid>
        <Grid item xs={isSmall ? 12 : undefined}>
          <PostTypeButton title="Create Manually" src={ManualIcon} onClick={props.handleManual} />
        </Grid>
      </Grid>
    </Box>
  );
};

export const PostCreation: React.FC<{
  open: boolean;
  toggle: () => void;
  refresh: () => void;
}> = ({ open, toggle, refresh }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [type, setType] = useState<'ai' | 'reword' | 'manual'>('ai');

  const firstStep = (
    <PostCreationTypeSelection
      handleCreateAI={() => {
        setType('ai');
        setActiveStep(1);
      }}
      handleReword={() => {
        setType('reword');
        setActiveStep(1);
      }}
      handleManual={() => {
        setType('manual');
        setActiveStep(1);
      }}
    />
  );

  useEffect(() => {
    if (activeStep === 0) {
      setType('ai');
    }
  }, [activeStep]);

  return (
    <Dialog fullWidth scroll="paper" maxWidth="md" open={open} onClose={toggle}>
      <DialogTitle>Create Post</DialogTitle>
      {type === 'ai' && (
        <CreateWithAI
          open={open}
          toggle={toggle}
          refresh={refresh}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        >
          {firstStep}
        </CreateWithAI>
      )}
      {type === 'reword' && (
        <CreateByReword
          open={open}
          toggle={toggle}
          refresh={refresh}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        >
          {firstStep}
        </CreateByReword>
      )}
      {type === 'manual' && (
        <CreateManually
          open={open}
          toggle={toggle}
          refresh={refresh}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        >
          {firstStep}
        </CreateManually>
      )}
    </Dialog>
  );
};
