import React from 'react';
import { Box, CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import {
  ArrowForwardRounded
} from '@material-ui/icons';

interface Props {
  conclusions: string[];
  loading: boolean;
  handleConclusionSelection: (topicSelect?: string) => void
}

const Conclusions: React.FC<Props> = ({ conclusions, loading, handleConclusionSelection }) => {

  const handleClick = (conclusions: string) => {
    handleConclusionSelection(conclusions)
  }


  return (
    // <Box>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={loading ? 2 : 1}>
                <Box fontWeight="bold">Suggested Conclusions</Box>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {loading && (
          <TableBody>
            <TableCell colSpan={2}>
              <Box
                style={{
                  width: "100%",
                  minHeight: "300px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <CircularProgress />
                </Box>
                <Box>
                  <Typography variant="subtitle2">Generating Conclusions</Typography>
                </Box>
              </Box>
            </TableCell>
            <TableCell />
          </TableBody>
        )}
          {!loading && conclusions.length !== 0 && (
            <TableBody>
              {conclusions.map((topic, index) => (
                <TableRow key={index} onClick={() => { handleClick(topic) }} style={{ cursor: 'pointer' }}>
                  <TableCell>{topic}</TableCell>
                  <TableCell align='right'>
                    <ArrowForwardRounded />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </Paper>
    // </Box>
  )
}

export default Conclusions;
